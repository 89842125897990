import { ScrollArea, createStyles } from '@mantine/core';

import dt from '~/testing';
import { Text } from '~/domains/design-system/Text';

const useStyles = createStyles((theme) => ({
  container: {},
  list: {
    display: 'flex',
    gap: theme.other.spacing._8,
    marginTop: theme.other.spacing._8,
  },
  item: {
    width: 94,
    height: 48,
    border: `1px solid ${theme.colors.gray[0]}`,
    borderRadius: theme.other.spacing._4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export type PlayerGroupedStat = {
  value: number | string;
  shouldRound?: boolean;
  name: string;
  round?: boolean;
};

export type PlayerGroupedStats = {
  orderedStats: PlayerGroupedStat[];
  name: string;
};

type GamecenterPlayerCardStatsProps = {
  groupedStats?: PlayerGroupedStats;
};

function GamecenterPlayerCardStats({ groupedStats }: GamecenterPlayerCardStatsProps) {
  const { classes, theme } = useStyles();

  if (!groupedStats || !groupedStats.orderedStats?.length) {
    return null;
  }

  return (
    <div
      className={classes.container}
      data-test-id={dt.contest.game.common.dfs.playerDetail.season.container}
    >
      <Text variant="headline-medium" color={theme.colors.gray2[6]}>
        {groupedStats.name}
      </Text>
      <ScrollArea w="100%" type="never">
        <div
          className={classes.list}
          data-test-id={dt.contest.game.common.dfs.playerDetail.season.items}
        >
          {groupedStats.orderedStats.map((stat) => (
            <div
              data-test-id={dt.contest.game.common.dfs.playerDetail.season.item}
              key={stat.name}
              className={classes.item}
            >
              <Text
                variant="display-headline-condensed-large"
                align="center"
                color={theme.colors.gray2[6]}
              >
                {stat.value}
              </Text>
              <Text variant="display-body-medium" align="center" color={theme.colors.gray2[3]}>
                {stat.name}
              </Text>
            </div>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
}

export default GamecenterPlayerCardStats;
