import { Modal, useMantineTheme } from "@mantine/core";

function GamecenterPlayerDetailModalContainer({
  header,
  children,
  isOpen,
  onClose,
}: {
  header: JSX.Element;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}) {
  const theme = useMantineTheme();

  return (
    <Modal
      id="player-card-modal"
      radius="lg"
      centered
      opened={isOpen}
      onClose={onClose}
      overflow="inside"
      p={0}
      title={header}
      styles={{
        inner: {
          paddingTop: theme.other.spacing._32,
          paddingBottom: theme.other.spacing._32,
        },
        modal: {
          maxHeight: `calc(100svh - ${theme.other.spacing._32 * 2}px)`,
          '&.mantine-Paper-root': {
            padding: 0,
            overflow: 'hidden',
          },
        },
        header: {
          marginRight: 0,
          marginBottom: 0,
        },
        body: {
          maxHeight: `calc(100svh - ${theme.other.spacing._32 * 2 + 140}px)`,
        },
        title: {
          width: '100%',
          marginRight: 0,
        },
        close: {
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'white',
          backgroundColor: 'black',
          borderRadius: '50%',
          opacity: 0.4,
          '&:hover': {
            backgroundColor: 'black',
            opacity: 0.7,
            transition: 'opacity 0.2s ease-in-out',
          },
        },
      }}
    >
      {children}
    </Modal>
  )
}

export default GamecenterPlayerDetailModalContainer;