import { Flex, Text, useMantineTheme } from '@mantine/core';

import { CurrencyConvertor } from '~/components/utils/formatters';

type PlayerCardTagProps = {
  className: string;
  tag?: {
    position?: string;
    salary?: number;
    injury?: string;
    playerTier?: string;
  };
};

function PlayerCardTag({ tag, className }: PlayerCardTagProps) {
  const theme = useMantineTheme();

  return (
    <>
      {tag?.playerTier || tag?.position ? (
        <Flex
          className={className}
          left={0}
          bottom={0}
          sx={{ borderTopRightRadius: 8 }}
          bg={theme.white}
        >
          <Text weight="bold" size="xs">
            {tag.playerTier || tag.position}
          </Text>
        </Flex>
      ) : null}

      {tag?.salary ? (
        <Flex
          className={className}
          left={0}
          top={0}
          sx={{
            borderBottomRightRadius: 8,
          }}
          bg={theme.white}
        >
          <Text weight="bold" size="sm" color={theme.colors.green[5]}>
            {CurrencyConvertor(tag.salary / 100)}
          </Text>
        </Flex>
      ) : null}

      {tag?.injury ? (
        <Flex
          className={className}
          right={0}
          bottom={0}
          sx={{ borderTopLeftRadius: 8 }}
          bg={theme.colors.red[9]}
        >
          <Text weight="bold" size="sm" color="white">
            {tag.injury}
          </Text>
        </Flex>
      ) : null}
    </>
  );
}

export default PlayerCardTag;
