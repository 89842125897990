import { useQuery } from '@tanstack/react-query';

import { GamesQueryKeys } from '../constants';
import { getPlayerDetail } from '../services/games-service.service.api';

const STALE_TIME = 30_000;

const usePlayerDetail = ({
  playerId,
  slateId,
  seasonId,
  contestId,
}: {
  playerId: string;
  slateId?: string;
  seasonId?: string;
  contestId?: string;
}) =>
  useQuery({
    queryKey: GamesQueryKeys.PLAYER_DETAIL({ playerId, slateId, seasonId }),
    queryFn: () => getPlayerDetail({ playerId, slateId, seasonId, contestId }),
    staleTime: STALE_TIME,
    enabled: !!playerId,
  });

export default usePlayerDetail;
