// Higher level keys are given from SR
export const golfPlayerStatusKey = {
  CUT: {
    value: 'MC',
    label: 'Missed Cut',
  },
  WD: {
    value: 'WD',
    label: 'Withdrew',
  },
  MDF: {
    value: 'DNF',
    label: 'Made Cut, Did Not Finish',
  },
  DQ: {
    value: 'DQ',
    label: 'Disqualified',
  },
  DNS: {
    value: 'DNP',
    label: 'Did Not Play',
  },
};
