import type { PlayerDetail } from '~/domains/games/interfaces/player-detail.interface';

import type { PlayerGroupedStat } from '../../../components/GamecenterPlayerDetail/GamecenterPlayerDetailStats';

const parseQB = (playerDetail: PlayerDetail): PlayerGroupedStat[] => [
  {
    name: 'COMP %',
    value: playerDetail.attributes?.passing?.cmp_pct.toFixed(2) ?? '-',
  },
  {
    name: 'YDS',
    value: playerDetail.attributes?.passing?.yards ?? '-',
  },
  {
    name: 'TD',
    value: playerDetail.attributes?.passing?.touchdowns ?? '-',
  },
  {
    name: 'INT',
    value: playerDetail.attributes?.passing?.interceptions ?? '-',
  },
  {
    name: 'RUSH YDS',
    value: playerDetail.attributes?.rushing?.yards ?? '-',
  },
];

export default parseQB;
