import { useInfiniteQuery } from '@tanstack/react-query';

import { GamesQueryKeys } from '../constants';
import { getPlayerSeasonData } from '../services/games-service.service.api';

const STALE_TIME = 30_000;
const PAGE_SIZE = 20;

const usePlayerSeasonData = ({
  playerId,
  seasonId,
  enabled = true,
}: {
  playerId: string;
  seasonId?: string;
  enabled?: boolean;
}) =>
  useInfiniteQuery({
    queryKey: GamesQueryKeys.PLAYER_SEASON_DATA({ playerId, seasonId }),
    queryFn: ({ pageParam = 0 }) =>
      getPlayerSeasonData({ playerId, seasonId, limit: PAGE_SIZE, offset: pageParam }),
    staleTime: STALE_TIME,
    enabled: !!playerId && enabled,
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.offset + PAGE_SIZE;
      if (nextPage > lastPage.total) {
        return undefined;
      }
      return nextPage;
    },
  });

export default usePlayerSeasonData;
