const GAMES_QUERY_KEY_PREFIX = 'games';

const GamesQueryKeys = {
  PLAYER_DETAIL: ({
    playerId,
    slateId,
    seasonId,
  }: {
    playerId: string;
    slateId?: string;
    seasonId?: string;
  }) => [GAMES_QUERY_KEY_PREFIX, 'playerDetail', playerId, slateId, seasonId],
  PLAYER_SEASON_DATA: ({ playerId, seasonId }: { playerId: string; seasonId?: string }) => [
    GAMES_QUERY_KEY_PREFIX,
    'playerSeasonData',
    playerId,
    seasonId,
  ],
};

export { GamesQueryKeys };
