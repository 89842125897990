import type { CSSProperties } from 'react';
import { useState } from 'react';
import Image from 'next/image';

import Silhouette from '~/components/assets/Silhouette.png';
import { getCdnUrl } from '~/domains/assets/utils/getCdnUrl';
import { LeagueAlias } from '~/domains/common/enums/league-alias.enum';

interface ProfileImageProps {
  fallback?: typeof Silhouette;
  alt: string;
  playerId?: string;
  src?: string;
  height?: number;
  width?: number;
  style?: CSSProperties;
  className?: string;
  leagueAlias?: string;
}

function ProfileImage({
  fallback = Silhouette,
  alt,
  playerId = '',
  src = '',
  leagueAlias = LeagueAlias.PGA,
  ...props
}: ProfileImageProps) {
  const imageSrc = playerId ? getCdnUrl(`/${leagueAlias}/${playerId}.png`) : src;
  const [error, setError] = useState(null);

  const profileImageKey = imageSrc.split('/').pop();

  return (
    <Image
      key={profileImageKey}
      alt={alt}
      onError={setError}
      onLoad={() => setError(null)}
      src={error ? fallback : imageSrc}
      unoptimized
      {...props}
    />
  );
}

export default ProfileImage;
