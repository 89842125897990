import type { PlayerDetail } from '~/domains/games/interfaces/player-detail.interface';

import type { PlayerGroupedStat } from '../../../components/GamecenterPlayerDetail/GamecenterPlayerDetailStats';

const parseRB = (playerDetail: PlayerDetail): PlayerGroupedStat[] => [
  {
    name: 'ATT',
    value: playerDetail.attributes?.rushing?.attempts ?? '-',
  },
  {
    name: 'RUSH YDS',
    value: playerDetail.attributes?.rushing?.yards ?? '-',
  },
  {
    name: 'TD',
    value: playerDetail.attributes?.rushing?.touchdowns ?? '-',
  },
  {
    name: 'REC',
    value: playerDetail.attributes?.receiving?.receptions ?? '-',
  },
  {
    name: 'REC YDS',
    value: playerDetail.attributes?.receiving?.yards ?? '-',
  },
];

export default parseRB;
