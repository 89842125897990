import type { ContestSettings } from '@betterpool/api-types/contests-service';
import { Sport } from '@betterpool/api-types/contests-service';

import type { PlayerDetail } from '~/domains/games/interfaces/player-detail.interface';

import type { PlayerGroupedStats } from '../../components/GamecenterPlayerDetail/GamecenterPlayerDetailStats';

import parseFootball from './parseFootballGroupedStats/parseFootballGroupedStats';
import parseGolf from './parseGolf';
import parseBasketball from './parseBasketball';

const parsers = {
  [Sport.FOOTBALL]: parseFootball,
  [Sport.GOLF]: parseGolf,
  [Sport.BASKETBALL]: parseBasketball,
};

const parsePlayerGroupedStats = (
  playerData: PlayerDetail,
  sport: Sport,
  scoreType?: ContestSettings['scoreType']
): PlayerGroupedStats => {
  const parser = parsers[sport] ?? (() => ({}));

  return parser(playerData, scoreType);
};

export default parsePlayerGroupedStats;
