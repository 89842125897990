import { Box } from '@mantine/core';

type GamecenterPlayerCardHeaderBackgroundProps = {
  image: string;
};

function GamecenterPlayerCardHeaderBackground({
  image,
}: GamecenterPlayerCardHeaderBackgroundProps) {
  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 0,
        top: 0,
        bottom: 0,
        right: 120,
        width: '150px',
        backgroundImage: `url(${image})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        opacity: 0.08,
        backgroundPosition: 'center',
      }}
    >
      {!image && (
        <svg
          width="110%"
          height="180"
          viewBox="0 0 400 140"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path
            d="M250.48 -40.7998C236.321 -37.1969 226.66 -43.1702 218.703 -48.2071L218.231 -48.4935C212.555 -51.9365 207.687 -54.8895 202.357 -53.5332C194.916 -51.6397 192.89 -47.4353 190.115 -41.565C187.842 -36.6654 184.889 -30.6441 177.655 -24.4822C169.651 -17.732 154.945 -19.9991 138.052 -22.6034L137.739 -22.6517C121.26 -25.3091 102.622 -28.1551 85.5689 -23.8158C59.8342 -17.2675 40.1862 -2.8878 25.8667 7.60654L24.7633 8.40707L24.7623 8.40785C20.0839 11.8028 16.0386 14.7384 12.5378 16.9003C3.98523 21.9222 -0.649001 16.8831 -5.49757 11.477C-9.20648 7.36175 -13.0465 3.1745 -19.0513 2.69996C-32.929 1.80462 -49.9946 22.5887 -50.1458 22.8379L-50.9878 22.209C-50.2519 21.284 -33.3376 0.749231 -18.991 1.73605C-12.545 2.20373 -8.38729 6.73172 -4.67838 10.8469L-4.5195 11.0197C0.204901 16.1561 4.25517 20.5596 11.9503 15.9958C15.7514 13.7639 20.1574 10.5348 25.2036 6.82672L25.607 6.53246C39.9935 -3.96276 59.5911 -18.2596 85.2915 -24.7992C102.442 -29.1633 120.863 -26.3439 137.267 -23.8333L138.19 -23.6918C154.847 -21.1334 169.317 -18.911 176.964 -25.3603C184.046 -31.273 186.745 -37.0187 189.094 -42.0429C191.869 -47.9133 194.094 -52.5899 202.052 -54.6149C207.882 -56.0984 213.086 -52.8804 219.046 -49.195L219.233 -49.0795L219.354 -49.0043C228.723 -43.1932 239.34 -36.6081 256.548 -44.03C268.621 -49.1954 278.046 -56.3894 287.102 -63.3018L287.292 -63.4465L288.279 -64.18C300.699 -73.4147 313.583 -82.9947 333.619 -88.0929L333.897 -87.1096C313.536 -81.9288 300.581 -72.0976 288.011 -62.47L287.469 -62.0598C278.421 -55.2039 269.081 -48.1264 257.06 -43.0009C254.794 -42.0027 252.548 -41.3258 250.48 -40.7998Z"
            fill="white"
          />
          <path
            d="M251.626 -36.3484C237.478 -32.7484 227.904 -38.4146 220.062 -43.056L220.043 -43.0673C214.322 -46.4598 209.285 -49.3939 203.911 -48.0264C196.527 -46.1475 194.448 -42.0903 191.624 -36.5807L191.558 -36.4515C189.229 -31.7486 186.22 -25.924 178.903 -20.057C170.886 -13.5364 156.473 -15.4846 139.676 -17.7552L139.099 -17.8332C122.73 -20.0972 104.176 -22.6483 87.1227 -18.309C61.2847 -11.7344 41.5257 2.25199 27.123 12.4513L26.5685 12.8408C21.6282 16.311 17.3755 19.2982 13.7108 21.4501C5.13047 26.3736 0.551734 21.5312 -4.18585 16.5184L-4.31368 16.3837C-7.90137 12.6027 -11.6937 8.60603 -17.5731 8.33142C-30.1502 7.66679 -45.7034 24.5015 -48.4226 27.4447C-48.6694 27.7118 -48.8106 27.8646 -48.834 27.8794L-49.5728 27.2242C-49.5425 27.1914 -49.4864 27.1286 -49.4058 27.0383C-47.4554 24.8543 -31.1344 6.57853 -17.4372 7.24288C-11.028 7.61171 -6.92827 11.9314 -3.30634 15.7476L-3.29106 15.7637L-3.09053 15.9731C1.5333 20.8004 5.5287 24.9716 13.2266 20.5193C16.6956 18.5862 20.5763 15.8563 25.1256 12.656L25.1279 12.6543C25.5991 12.3229 26.0774 11.9864 26.5632 11.6452C40.9382 1.34753 60.8005 -12.6651 86.8453 -19.2924C103.9 -23.6321 122.131 -21.153 138.383 -18.9429L139.343 -18.8124C156.015 -16.5335 170.502 -14.5534 178.268 -20.7385C185.391 -26.4426 188.149 -31.9804 190.55 -36.8009L190.565 -36.8311C193.395 -42.5048 195.675 -46.9847 203.633 -49.0097C209.524 -50.5087 214.589 -47.4764 220.6 -43.8414C229.915 -38.3072 240.411 -32.1246 257.852 -39.4082C270.037 -44.5113 279.489 -51.4484 288.659 -58.2084C301.416 -67.5673 314.558 -77.1297 335.229 -82.3894L335.506 -81.4061C315.129 -76.2209 302.113 -66.7432 289.483 -57.5453L289.322 -57.4285C280.152 -50.6686 270.597 -43.7052 258.308 -38.5758C255.939 -37.5513 253.796 -36.9007 251.626 -36.3484Z"
            fill="white"
          />
          <path
            d="M221.541 -37.7572C229.304 -33.4087 238.771 -28.1238 252.93 -31.7267C254.997 -32.2526 257.215 -33.0279 259.453 -34.1244C271.77 -39.1555 281.381 -45.9222 290.606 -52.4855C303.239 -61.4968 316.465 -70.7643 336.929 -75.9714L336.651 -76.9547C315.981 -71.695 302.755 -62.4276 289.915 -53.3637C280.69 -46.8004 271.182 -40.0599 258.969 -35.0552C241.501 -27.8699 231.116 -33.6591 221.912 -38.8C215.984 -42.14 210.872 -44.9494 205.084 -43.4767C197.126 -41.4517 194.79 -37.1684 191.876 -31.7897C189.417 -27.1589 186.579 -21.9048 179.413 -16.2872C171.744 -10.1794 157.576 -11.8427 141.149 -13.7712L140.443 -13.8541C124.158 -15.8231 105.583 -18.0528 88.3234 -13.661C62.6001 -7.11552 42.8263 6.34642 28.2864 16.2452L27.7438 16.6145L27.5533 16.7456C22.5335 20.2008 18.2362 23.1587 14.4551 25.2657C6.68722 29.5325 2.77178 25.6215 -1.78142 21.0734L-1.95162 20.9035C-5.52181 17.2799 -9.54077 13.2436 -15.9313 12.9726C-29.897 12.4859 -46.7799 30.8678 -48.0767 32.2796L-48.13 32.3376L-47.2879 32.9665C-47.2793 32.9592 -47.2304 32.9074 -47.1437 32.8155C-45.4319 30.9999 -28.9671 13.5368 -15.9361 14.1332C-10.0238 14.3124 -6.29717 18.0948 -2.73207 21.7131L-2.71532 21.7302C1.96678 26.5463 6.46228 31.0937 15.0703 26.2685C18.8992 24.1348 23.3607 21.1025 28.4624 17.591L28.7291 17.4095C43.2936 7.49535 63.0788 -5.9726 88.7597 -12.5073C105.813 -16.8465 124.153 -14.6625 140.438 -12.6935L140.509 -12.6851C157.44 -10.6807 172.082 -8.94729 180.235 -15.337C187.477 -21.0793 190.569 -26.6089 192.953 -31.1151L192.964 -31.1352C195.871 -36.5035 197.985 -40.4055 205.52 -42.323C210.894 -43.6905 215.876 -40.9531 221.541 -37.7572Z"
            fill="white"
          />
          <path
            d="M254.282 -27.3281C240.122 -23.7252 230.767 -28.6168 223.087 -32.6703C217.478 -35.6695 212.552 -38.2102 207.177 -36.8427C199.632 -34.9229 197.468 -31.2102 194.602 -26.0545C192.163 -21.7449 189.015 -16.412 181.615 -10.8401C173.377 -4.74382 158.917 -6.21139 142.065 -7.92165L141.945 -7.93383C125.654 -9.58007 107.318 -11.433 90.2857 -7.09902C64.551 -0.550677 44.6457 12.5244 30.0765 22.1337C29.6076 22.4419 29.144 22.7471 28.6857 23.0487C24.0304 26.1133 19.924 28.8165 16.3189 30.6934C7.80349 35.2428 3.49507 31.187 -1.0885 26.8722L-1.32806 26.6467C-4.84275 23.2199 -8.48854 19.7209 -14.4102 19.5414C-27.4162 19.2244 -44.233 36.2447 -45.8512 37.8824C-45.926 37.9582 -45.9683 38.001 -45.9763 38.0077L-46.715 37.3525C-45.9513 36.5258 -28.5299 18.1807 -14.3776 18.4792C-8.07421 18.6502 -4.12555 22.4201 -0.662183 25.7266L-0.56437 25.82L-0.350863 26.0233C4.10122 30.2639 7.96466 33.9437 15.8347 29.7626C19.322 27.9222 23.3883 25.2451 28.0039 22.2064L28.0085 22.2034C28.4958 21.8825 28.9894 21.5576 29.489 21.2292L30.0688 20.8514C44.6161 11.3709 64.5106 -1.59435 90.0083 -8.08236C107.268 -12.4742 125.731 -10.6378 142.065 -8.89176L142.301 -8.86754C158.742 -7.18411 173.024 -5.72165 180.848 -11.5936C187.944 -16.9205 190.861 -21.8964 193.373 -26.1816L193.506 -26.4078C196.474 -31.5898 198.866 -35.6764 206.824 -37.7014C212.612 -39.1741 217.669 -36.5614 223.513 -33.5164L223.568 -33.488C232.648 -28.7474 242.915 -23.3869 260.404 -30.3616C272.617 -35.3663 282.208 -41.8118 291.414 -48.0537L291.671 -48.2284C304.513 -56.9429 317.755 -65.9284 338.288 -71.1532L338.566 -70.1698C317.999 -64.9364 304.793 -55.9902 291.973 -47.2476L291.465 -46.9051C282.312 -40.7408 272.776 -34.3182 260.785 -29.4045C258.567 -28.6293 256.349 -27.8541 254.282 -27.3281Z"
            fill="white"
          />
          <path
            d="M224.558 -27.4583C232.154 -23.6998 241.399 -19.2016 255.558 -22.8045C257.625 -23.3305 259.768 -23.9811 262.268 -24.9335C274.64 -29.7679 284.362 -36.1413 293.802 -42.3376C306.602 -50.7589 319.966 -59.5346 340.326 -64.7154L340.049 -65.6988C319.482 -60.4654 306.014 -51.6633 293.111 -43.2157C283.775 -37.0458 274.053 -30.6724 261.784 -25.8643C244.318 -19.0079 234.17 -23.9277 225.22 -28.2666L225.087 -28.3308C219.299 -31.179 214.297 -33.5951 208.509 -32.1224C200.551 -30.0974 198.104 -26.2075 195.08 -21.2221C192.509 -16.9846 189.533 -12.2222 182.256 -6.99794C174.309 -1.32096 160.3 -2.48217 143.936 -3.83865L143.407 -3.88254C127.203 -5.2338 108.852 -6.76405 91.6176 -2.37875C65.4947 4.26835 45.2101 17.1089 30.4579 26.4472L30.4151 26.4743C29.9474 26.7679 29.4855 27.0583 29.0292 27.345C24.5268 30.1748 20.5745 32.6588 17.1666 34.4829C9.16006 38.6236 5.40984 35.1743 0.934441 31.0579L0.906225 31.032C-2.55298 27.8018 -6.43321 24.2571 -12.7682 24.1828C-26.194 24.0858 -42.8114 40.0968 -45.0469 42.2507C-45.1586 42.3584 -45.2345 42.4314 -45.2721 42.4661L-44.4301 43.095C-44.4231 43.0892 -44.3895 43.0568 -44.3306 43C-42.8649 41.5891 -25.7255 25.089 -12.6975 25.2187C-6.80361 25.2999 -3.24106 28.5038 0.218138 31.734L0.372314 31.8743C4.93178 36.0215 9.15957 39.8671 17.7541 35.3873C20.9137 33.6961 24.5412 31.4377 28.7029 28.8467C29.4849 28.3599 30.2858 27.8613 31.1059 27.3525C45.7583 18.0382 65.9536 5.20554 91.8951 -1.3954C108.936 -5.73164 127.199 -4.16915 143.31 -2.79084L143.344 -2.78795C160.138 -1.37005 174.54 -0.186561 182.843 -6.09349C190.299 -11.4687 193.502 -16.605 195.997 -20.7179L196.089 -20.8642C199.094 -25.6771 201.317 -29.2385 208.787 -31.1391C214.161 -32.5066 219.032 -30.1625 224.558 -27.4583Z"
            fill="white"
          />
          <path
            d="M256.572 -18.4253C242.616 -14.8741 233.614 -18.9217 226.16 -22.2734L226.001 -22.3451C220.53 -24.8526 215.715 -27 210.34 -25.6325C202.815 -23.7176 200.559 -20.3126 197.464 -15.6409L197.44 -15.6047C194.889 -11.6884 191.631 -6.74883 184.119 -1.57026C175.761 4.14 161.414 3.15318 144.807 2.00399C128.771 0.919972 110.605 -0.254384 93.4488 4.11118C67.7141 10.6595 47.4834 22.9742 32.7477 31.9935C27.4594 35.2362 22.9147 37.9736 18.927 39.9369C10.3962 44.2731 6.21994 40.7414 1.82132 37.0216L1.52978 36.7752L1.46672 36.7204C-1.89027 33.8015 -5.4175 30.7346 -11.2471 30.7516C-24.2983 30.8113 -41.6463 46.8453 -42.9396 48.0406C-42.9837 48.0814 -43.0092 48.1049 -43.0151 48.1099L-43.8571 47.481C-43.836 47.4615 -43.8026 47.4304 -43.7575 47.3884C-42.1155 45.8569 -24.8802 29.7817 -11.2145 29.6894C-4.93287 29.6655 -1.11363 32.9862 2.25228 35.9127L2.36654 36.0121C6.73912 39.8174 10.4363 43.0348 18.4428 39.0061C22.3272 37.0691 26.8719 34.3318 32.1602 31.089C46.9992 22.0434 67.2299 9.72876 93.1713 3.12783C110.291 -1.22836 128.218 -0.0522189 144.187 0.995454L144.867 1.04008C161.24 2.14353 175.456 3.05832 183.456 -2.35008C190.789 -7.37771 193.821 -11.9434 196.447 -15.9843L196.499 -16.0635C199.582 -20.7173 202.073 -24.4775 209.884 -26.4649C215.663 -27.9355 220.604 -25.7245 226.299 -23.1757L226.323 -23.1649C235.223 -19.1057 245.246 -14.5949 262.826 -21.3868C274.991 -26.1686 284.693 -32.2207 294.009 -38.0693C307.099 -46.2482 320.678 -54.657 341.451 -59.943L341.729 -58.9596C321.162 -53.7262 307.686 -45.3438 294.7 -37.1912L294.689 -37.184C285.273 -31.3113 275.574 -25.2622 263.31 -20.456C260.989 -19.6545 258.639 -18.9513 256.572 -18.4253Z"
            fill="white"
          />
          <path
            d="M227.471 -17.1332C234.873 -14.0631 243.896 -10.3515 257.952 -13.9281C259.925 -14.4301 262.061 -15.0696 264.294 -15.7383L264.614 -15.8342C276.977 -20.5562 286.713 -26.3909 296.162 -32.0533L296.246 -32.1039C309.316 -39.9615 322.874 -48.049 343.442 -53.2824L343.164 -54.2657C322.742 -49.0693 309.289 -41.0167 296.265 -33.2209L295.79 -32.9364L294.859 -32.3859C285.744 -26.9977 276.227 -21.3719 264.337 -16.8175C246.626 -10.0977 236.713 -14.2152 227.925 -17.881C222.275 -20.2377 217.384 -22.2604 211.597 -20.7877C203.742 -18.789 201.184 -15.2924 198.021 -10.7987C195.339 -6.95452 192.252 -2.58549 184.864 2.24547C176.836 7.55553 162.703 6.93575 146.31 6.1536C130.358 5.36459 112.172 4.51153 94.9118 8.90339C68.9704 15.5043 48.5254 27.452 33.6032 36.2026L33.3922 36.3241C28.24 39.2932 23.7381 41.8874 19.9059 43.7983C11.9028 47.7141 8.25752 44.729 3.98889 41.2335L3.86751 41.1341C0.547041 38.3956 -3.19447 35.3426 -9.474 35.4649C-23.4876 35.6581 -41.5194 51.8399 -42.3109 52.5682L-41.5444 53.3218C-41.3932 53.0725 -23.0791 36.7135 -9.53434 36.4288C-3.69597 36.3134 -0.2444 39.1239 3.07607 41.8624C7.42521 45.4986 11.4844 48.8923 20.2589 44.657C24.1433 42.7201 28.7158 40.0811 34.0318 36.9366L34.5612 36.6291C49.3607 28.0293 69.5259 16.3115 95.0582 9.81471C112.111 5.47545 130.166 6.25646 146.118 7.04548L146.656 7.06914C163.027 7.78927 177.073 8.40718 185.396 2.95325C192.859 -2.00234 196.201 -6.64693 198.807 -10.3665C201.97 -14.8602 204.274 -18.0812 211.819 -20.001C217.296 -21.3948 222.056 -19.4441 227.471 -17.1332Z"
            fill="white"
          />
          <path
            d="M259.125 -9.37849C245.076 -5.80367 236.269 -9.14308 228.953 -11.9174L228.942 -11.9216C223.61 -13.9374 218.905 -15.6915 213.428 -14.2976C205.883 -12.3778 203.524 -9.35349 200.306 -5.0565C197.644 -1.5336 194.247 2.91433 186.728 7.67325C178.258 12.9902 164.078 12.5934 147.637 12.0342C131.768 11.5402 113.796 11.0542 96.743 15.3934C71.1745 21.8995 50.7785 33.2818 35.8882 41.5915L35.4191 41.8533C34.3791 42.43 33.3663 42.9957 32.3803 43.5464C28.3716 45.7853 24.806 47.7768 21.6663 49.2524C12.9901 53.3523 8.88184 50.2088 4.6554 46.9749L4.62219 46.9495C1.35722 44.4076 -2.03886 41.7937 -7.84948 42.0076C-21.311 42.5872 -39.9227 58.2842 -40.1016 58.4351L-40.8681 57.6816C-40.2077 56.8812 -21.8506 41.4598 -8.05134 40.8996C-1.89033 40.6821 1.77888 43.4529 4.98239 45.872L5.12369 45.9787L5.2045 46.0406C9.40727 49.2566 12.9685 51.9818 21.0511 48.2496C24.9632 46.411 29.4601 43.8966 34.7005 40.8768C49.706 32.4212 70.262 20.8669 96.3067 14.2397C113.205 9.93994 130.91 10.3847 146.511 10.7765L147.511 10.8016L147.535 10.8023C163.835 11.2891 177.902 11.7093 185.981 6.59843C193.425 1.96414 196.568 -2.20823 199.305 -5.85576C202.523 -10.1527 205.137 -13.4527 212.991 -15.4514C218.724 -16.91 223.522 -15.1326 229.022 -13.0955L229.181 -13.0364L229.312 -12.9867C237.6 -9.84066 247.781 -5.97607 265.427 -12.5629C277.018 -16.9119 286.443 -22.2268 295.539 -27.3563L297.018 -28.1901L297.585 -28.5147C310.664 -36.0033 324.185 -43.745 344.662 -48.9557L344.94 -47.9724C324.373 -42.739 310.731 -34.9465 297.578 -27.3839L297.409 -27.2864C287.931 -21.8353 278.162 -16.2162 265.911 -11.6322C263.541 -10.6077 261.295 -9.93076 259.125 -9.37849Z"
            fill="white"
          />
          <path
            d="M230.832 -6.57079C237.96 -4.21242 246.617 -1.34763 260.401 -4.85503C262.468 -5.381 264.817 -6.0842 267.318 -7.03667C279.67 -11.5497 289.641 -17.0382 299.228 -22.3231C312.464 -29.5907 326.085 -37.0618 346.652 -42.2952L346.375 -43.2785C326.055 -38.108 312.474 -30.6801 299.347 -23.5006L298.668 -23.1292C289.082 -17.8443 279.186 -12.4805 266.834 -7.96742C249.277 -1.52113 239.222 -4.90892 231.074 -7.65458L230.783 -7.75256C225.375 -9.64381 220.595 -11.2732 214.807 -9.80049C206.95 -7.8012 204.377 -4.68062 201.135 -0.750188L201.01 -0.598218L200.767 -0.306209C197.932 3.10099 194.706 6.97836 187.416 11.2923C179.174 16.2353 165.284 16.0809 149.057 15.8887C133.167 15.716 115.176 15.5513 97.9159 19.9432C71.8711 26.5704 51.0731 37.6593 35.9843 45.8199C34.8514 46.4239 33.755 47.0137 32.6938 47.5846C28.7946 49.6822 25.3708 51.5241 22.355 52.8714C14.3085 56.587 10.7973 54.095 6.70062 51.1874L6.56639 51.0922L6.37992 50.9604C3.24222 48.7428 -0.367122 46.1919 -6.46991 46.5047C-20.2414 47.1633 -38.6894 61.87 -39.5565 62.723L-38.8934 63.5028C-38.7144 63.3519 -19.9085 48.3433 -6.47476 47.6653C-0.766405 47.3572 2.5622 49.7085 5.70734 51.9303L5.83044 52.0172C10.0131 55.0633 14.0369 57.9391 22.7636 53.9268C26.6124 52.1179 31.0539 49.7502 36.2395 46.9859L37.3315 46.4034C52.3707 38.3799 72.7543 27.505 98.2211 21.0249C115.378 16.6593 133.266 16.8503 149.052 17.0493C165.382 17.2152 179.479 17.317 187.928 12.3213C195.568 7.75547 199.025 3.52358 201.736 0.204714L201.776 0.155304L201.896 0.0106518C205.064 -3.79795 207.559 -6.79665 215.009 -8.69251C220.487 -10.0863 225.136 -8.52896 230.412 -6.70975L230.832 -6.57079Z"
            fill="white"
          />
          <path
            d="M261.753 -0.456302C247.834 3.08555 239.149 0.540935 232.063 -1.53538L231.855 -1.59638L231.645 -1.65923C226.412 -3.22862 221.969 -4.56129 216.563 -3.18579C209.044 -1.27254 206.657 1.37235 203.29 5.10197L203.116 5.29498C200.343 8.42454 196.807 12.3808 189.177 16.7464C180.664 21.6312 166.776 21.763 150.59 21.9166L150.205 21.9203C134.605 21.99 116.828 22.1923 99.775 26.5316C74.2458 33.0276 53.7707 43.5273 38.7077 51.2515L38.3449 51.4375C37.4279 51.9024 36.5307 52.3592 35.6534 52.8059L35.6452 52.8101L35.6442 52.8106C31.3332 55.0056 27.501 56.9568 24.1434 58.4238C15.4687 62.1927 11.6187 59.6953 7.47539 57.0076L7.32119 56.9076C4.19495 54.8574 0.937603 52.7352 -4.81753 53.1457C-18.168 54.1187 -37.1959 68.3406 -37.3749 68.4916L-38.038 67.7117C-37.2188 67.0817 -18.5766 53.0633 -4.88828 52.1098C1.25595 51.6959 4.79551 54.0175 7.90266 56.0555L7.98153 56.1072L8.07201 56.1662C12.1041 58.7937 15.5301 61.0262 23.6592 57.4931C27.5991 55.7528 32.3581 53.3825 37.7574 50.5331L38.1878 50.3131C53.3471 42.5659 73.9025 32.061 99.4975 25.5482C116.302 21.2722 133.624 21.0608 148.993 20.8733L150.237 20.858C166.381 20.7552 180.291 20.5883 188.589 15.8419C196.116 11.5026 199.398 7.82194 202.246 4.56775C205.603 0.76244 208.3 -2.24248 216.155 -4.24117C221.913 -5.70645 226.619 -4.29557 231.91 -2.7091L231.991 -2.68492C240.109 -0.218524 250.026 2.73841 267.876 -3.4898C280.255 -7.90453 290.207 -13.0717 299.745 -18.1336L300.552 -18.5545C313.722 -25.4241 327.362 -32.5386 347.619 -37.6929L347.896 -36.7096C327.329 -31.4762 313.521 -24.2738 300.229 -17.2028C290.588 -12.1146 280.636 -6.94748 268.153 -2.50645C266.066 -1.65917 263.82 -0.98227 261.753 -0.456302Z"
            fill="white"
          />
          <path
            d="M233.61 3.68778C240.596 5.46686 249.059 7.62198 262.926 4.0934C265.097 3.54113 267.343 2.86421 269.74 1.93805C282.147 -2.37835 292.154 -7.34881 301.851 -12.2404L302.814 -12.7186C316.019 -19.2761 329.709 -26.0743 349.788 -31.1834L349.51 -32.1668C328.737 -26.8807 314.845 -19.9733 301.367 -13.1711C291.67 -8.27956 281.663 -3.30911 269.359 0.980999C251.714 7.14742 241.789 4.57715 233.806 2.50987L233.668 2.47422L233.18 2.34914C228.017 1.02659 223.554 -0.116569 217.943 1.3113C209.985 3.33628 207.336 6.11824 203.923 9.72689L203.775 9.87851C200.806 12.9248 197.455 16.3625 189.997 20.4373C181.869 24.8935 168.302 25.3126 152.572 25.7984L151.859 25.8205C136.24 26.2115 118.442 26.7351 101.183 31.127C75.5455 37.6505 54.917 47.7375 39.6852 55.1856L39.1448 55.4498C38.1506 55.9333 37.1829 56.4066 36.2411 56.8672L36.2324 56.8714L36.224 56.8756C32.0057 58.9386 28.3068 60.7477 25.0667 62.0885C16.8514 65.5317 13.4712 63.5323 9.54821 61.2119L9.39668 61.1223C6.32593 59.2688 2.86188 57.1992 -3.30666 57.7148C-16.9672 58.7667 -35.8036 72.0968 -36.4917 72.7988L-35.8286 73.5787C-35.6497 73.4277 -16.4275 59.8942 -3.1048 58.8228C2.62258 58.314 5.82444 60.2396 8.89519 62.0931L9.02793 62.1714C13.096 64.5719 16.8634 66.7949 25.5786 63.1176C28.9424 61.7392 32.7053 59.8994 36.9884 57.8053C37.8889 57.365 38.8125 56.9135 39.7601 56.4526L40.0574 56.3068C55.211 48.8733 75.9206 38.7144 101.488 32.2087C118.392 27.9072 135.819 27.3612 151.242 26.878L151.93 26.8564L152.606 26.8346C168.455 26.3222 182.144 25.8797 190.584 21.3418C198.201 17.2101 201.797 13.4805 204.614 10.5592L204.69 10.4804C207.923 7.0227 210.6 4.33908 218.145 2.41928C223.599 1.03149 228.018 2.20555 233.257 3.59723L233.326 3.61536L233.61 3.68778Z"
            fill="white"
          />
          <path
            d="M264.306 8.59052C250.283 12.1587 241.794 10.3326 234.846 8.83772L234.796 8.82713L234.443 8.75182C229.41 7.67851 225.107 6.76066 219.754 8.12272C212.219 10.0401 209.512 12.6214 206.2 15.7787L206.188 15.7905L205.941 16.0246C203.095 18.7285 199.438 22.202 191.916 26.0619C183.301 30.4675 169.426 31.1524 153.345 31.8715C137.809 32.5575 120.226 33.4482 103.069 37.8138C77.6079 44.2926 56.918 53.958 41.6163 61.1064L40.9407 61.422C40.1408 61.7946 39.3579 62.1608 38.5917 62.5191C34.1063 64.6167 30.1917 66.4473 26.7793 67.7657C18.0208 71.3522 14.3371 69.4032 10.343 67.2901L10.2346 67.2328C7.21937 65.5759 4.10076 63.9454 -1.62663 64.4542C-14.9216 65.6239 -34.338 78.4691 -34.5169 78.6201L-35.0767 77.8139C-34.3886 77.1119 -15.3302 64.5685 -1.72513 63.3199C4.31231 62.7322 7.72087 64.6052 10.7361 66.262L10.839 66.3164C14.6994 68.356 17.9922 70.0959 26.2674 66.7366C30.2351 65.0946 34.9463 62.9473 40.3254 60.4192L40.8239 60.1863C56.1452 53.0294 76.9987 43.2882 102.661 36.7584C119.559 32.4586 136.892 31.5838 152.165 30.813L153.446 30.7485C169.267 29.9639 182.918 29.2868 191.301 25.0591C198.741 21.215 202.153 17.9959 205.156 15.163L205.394 14.9387L205.661 14.685C209.03 11.4878 211.768 8.89015 219.421 6.9427C224.92 5.54358 229.265 6.43551 234.221 7.4527C234.481 7.50622 234.744 7.56007 235.008 7.61394L235.364 7.68976C243.2 9.35898 252.997 11.446 270.532 5.53073C282.967 1.31266 293.029 -3.46113 302.782 -8.15602C316.24 -14.6369 330.215 -21.2493 350.885 -26.509L351.163 -25.5257C331.167 -20.4376 317.526 -13.9779 304.39 -7.7575L303.266 -7.22527C293.514 -2.53038 283.348 2.26971 270.913 6.48778C268.619 7.38765 266.373 8.06455 264.306 8.59052Z"
            fill="white"
          />
          <path
            d="M236.439 13.9311C243.295 15.1296 251.564 16.5754 265.554 13.0155C267.621 12.4895 269.895 11.911 272.292 10.9848C284.858 6.83877 294.976 2.26167 304.785 -2.23655L305.949 -2.75917C319.161 -8.6898 332.971 -14.8894 352.951 -19.9733L352.673 -20.9566C332.003 -15.6969 317.945 -9.37951 304.3 -3.16731C294.596 1.30461 284.478 5.88173 272.015 10.0015C254.148 15.913 244.465 14.1967 236.667 12.8145L236.582 12.7993C236.349 12.7601 236.118 12.721 235.888 12.682C230.95 11.8451 226.636 11.1142 221.106 12.5214C213.389 14.485 210.617 16.9197 207.154 19.9601L206.967 20.124C203.877 22.9129 200.402 25.9052 192.708 29.6545C184.434 33.7175 171.141 34.626 155.726 35.6795L154.662 35.7523C139.209 36.7333 121.605 37.9453 104.346 42.3371C78.6685 48.8708 57.7599 58.1863 42.3704 65.0428L41.7127 65.3359C39.9377 66.0973 38.2399 66.8392 36.6226 67.5458C33.2883 69.0026 30.2955 70.3103 27.6748 71.332C19.4279 74.5719 16.1773 73.0669 12.4313 71.3325L12.2545 71.2507C9.29475 69.7906 5.96942 68.2126 -0.143626 68.925C-13.5621 70.4422 -32.8425 82.1989 -33.6894 82.7306L-33.1019 83.6351C-32.9229 83.4841 -13.1813 71.3993 0.0304847 69.9346C5.54763 69.2484 8.62267 70.6794 11.4849 72.0114L11.7253 72.1232C15.575 73.9893 19.2936 75.7833 28.0556 72.289C32.0233 70.6471 36.6589 68.6243 42.0658 66.1946C57.4118 59.3386 78.5782 49.9477 104.52 43.3468C121.676 38.9812 139.176 37.7955 154.629 36.8145L155.661 36.7427C171.194 35.6623 184.605 34.7295 193.089 30.6115C200.858 26.7376 204.616 23.568 207.527 20.9302L207.691 20.7892C211.088 17.8635 213.78 15.5448 221.308 13.6294C226.817 12.2276 231.1 12.9861 236.144 13.8791L236.342 13.9142L236.439 13.9311Z"
            fill="white"
          />
          <path
            d="M266.727 17.5654C252.775 21.1157 244.604 20.0329 237.785 19.0278L237.77 19.0257C232.702 18.3154 228.437 17.7176 222.862 19.1363C215.248 21.0737 212.526 23.2288 209.017 26.0063L208.97 26.0438L208.618 26.3194C205.676 28.6255 201.885 31.5966 194.338 35.0368C185.718 39.0001 172.209 40.1965 156.569 41.5817L154.763 41.7427C139.723 43.088 122.775 44.604 106.073 48.8537C80.0287 55.4809 58.7513 64.4785 43.2187 71.0658C37.8874 73.3709 33.224 75.2953 29.332 76.8126C20.5145 80.1102 16.9068 78.7095 13.1404 77.1384L13.0867 77.1167C10.2263 75.9579 7.22091 74.7405 1.58431 75.4415C-11.5997 77.0046 -31.5356 88.4011 -31.7145 88.552L-32.302 87.6475C-32.1231 87.4966 -11.9805 76.0475 1.4102 74.4319C7.4199 73.7459 10.662 75.0288 13.5385 76.194L13.7992 76.2968C17.4278 77.7298 20.6421 78.9992 28.9512 75.8556C32.8432 74.3382 37.5066 72.4139 42.8379 70.1087C58.3705 63.5215 79.7513 54.4976 105.899 47.8441C122.704 43.568 139.657 42.0517 154.699 40.7062L155.918 40.5972L156.529 40.5437C172.052 39.1857 185.445 38.0142 193.957 34.0797C201.644 30.5555 205.18 27.7761 208.334 25.2974L208.41 25.2376L208.725 24.9928C212.211 22.2827 215.061 20.0673 222.687 18.1267C228.372 16.6803 232.847 17.228 238.052 18.0113C245.782 19.101 255.331 20.3599 273.291 14.5251C285.326 10.6435 295.272 6.39824 304.804 2.3293L305.64 1.9726L306.05 1.79572C319.649 -4.06644 333.688 -10.118 354.255 -15.3513L354.532 -14.368C333.964 -9.13431 319.888 -3.04809 306.326 2.8159L306.124 2.90335L304.896 3.4255C295.388 7.46808 285.547 11.6522 273.672 15.4821C271.172 16.4346 268.794 17.0395 266.727 17.5654Z"
            fill="white"
          />
          <path
            d="M239.316 24.2448C246.013 24.8555 254.196 25.6019 268.21 22.036C270.036 21.5715 271.92 21.0099 274.025 20.3826L274.026 20.3823C274.304 20.2993 274.586 20.2151 274.873 20.1299C287.522 16.2789 297.854 12.0688 307.773 7.96393L308.356 7.72624C321.881 2.204 335.866 -3.50546 356.142 -8.66481L355.864 -9.64816C335.386 -4.43745 321.262 1.32877 307.673 6.8767L307.289 7.03317L306.106 7.50977C296.521 11.3725 286.515 15.405 274.492 19.1729C256.483 24.8088 247.045 23.9461 239.428 23.2499L239.419 23.2491C234.297 22.7608 229.878 22.4098 224.193 23.8562C216.488 25.8168 213.606 27.8687 210.015 30.4243L209.606 30.7159C206.427 32.9781 202.829 35.5389 195.185 38.8259C186.61 42.5889 172.957 44.0604 157.257 45.7366C141.971 47.3077 124.665 49.1817 107.405 53.5736C81.1538 60.2534 59.662 68.8839 43.9428 75.2025C38.6871 77.383 34.0993 79.1827 30.2829 80.5754C21.8233 83.5711 18.6645 82.5832 14.9812 81.3071C12.1602 80.3386 8.97361 79.2524 3.01941 80.1351C-10.3914 82.072 -30.6526 92.7081 -30.8316 92.8591L-30.3474 93.7898C-30.1685 93.6389 -10.114 83.0554 3.09016 81.171C8.83767 80.3409 11.7621 81.2831 14.5831 82.2516L14.645 82.2731C18.3052 83.5413 21.7647 84.7401 30.5604 81.5587C33.2183 80.5966 36.1706 79.4141 39.4432 78.1033C40.9966 77.4811 42.623 76.8297 44.3236 76.1595C60.0429 69.841 81.5346 61.2104 107.683 54.5569C124.085 50.3832 140.65 48.5767 155.408 46.9672C156.086 46.8932 156.761 46.8196 157.431 46.7463C173.234 45.0438 186.887 43.5722 195.67 39.7566C203.356 36.3613 207.226 33.6363 210.243 31.5125L210.468 31.3536C213.952 28.7809 216.823 26.7856 224.471 24.8395C229.898 23.4586 234.184 23.8162 239.117 24.2277L239.256 24.2393L239.316 24.2448Z"
            fill="white"
          />
          <path
            d="M269.383 26.5858C255.431 30.1361 247.351 29.7679 240.83 29.4248C235.894 29.2053 231.633 29.0246 226.156 30.4184C218.796 32.291 215.93 34.0938 212.454 36.28L212.042 36.5392C208.965 38.587 205.012 41.0683 196.974 44.3785C188.212 47.8728 174.642 49.6393 159.025 51.6105L157.529 51.8054C142.626 53.7446 125.963 55.9131 109.264 60.1621C83.5295 66.7104 62.1058 74.7967 46.51 80.7676C40.9165 82.9287 36.0187 84.8073 31.8645 86.1806C23.0205 89.2714 19.648 88.3523 16.0612 87.3747L16.0259 87.3651C13.2604 86.5933 10.3915 85.8478 4.67172 86.7762C-8.50467 88.7589 -28.7534 98.6541 -28.9323 98.805L-29.4165 97.8742C-29.3974 97.8694 -29.2052 97.7789 -28.8614 97.6172L-28.8578 97.6156C-25.4767 96.0249 -7.53927 87.5865 4.60096 85.7403C10.4863 84.8678 13.5863 85.6307 16.3012 86.2989L16.3963 86.3223C19.9963 87.3034 23.0997 88.0946 31.587 85.1972C35.6379 83.8503 40.5357 81.9716 46.1292 79.8106C61.725 73.8396 83.1487 65.7534 108.987 59.1787C125.776 54.9066 142.628 52.7129 157.597 50.7642L158.851 50.6009C174.364 48.6559 187.831 46.9157 196.489 43.4477C204.171 40.3748 207.832 38.0307 211.029 35.9833L211.379 35.7593L211.443 35.7182C215.158 33.3092 218.067 31.4226 225.775 29.4614C231.459 28.015 235.823 28.1693 240.787 28.4872L241.119 28.5007C248.57 28.8028 257.888 29.1807 275.768 23.6964C287.516 20.1149 297.281 16.391 306.689 12.8037L306.69 12.8033L308.601 12.0746L309.436 11.7543C323.07 6.52604 337.189 1.1114 357.446 -4.04298L357.723 -3.05963C337.468 2.09436 323.311 7.53672 309.713 12.7639L309.085 13.0054C308.449 13.2477 307.812 13.4906 307.173 13.7342C297.766 17.3216 288 21.0456 276.252 24.6271C275.955 24.7152 275.661 24.8022 275.371 24.888L275.368 24.8888C273.174 25.5391 271.209 26.1213 269.383 26.5858Z"
            fill="white"
          />
          <path
            d="M242.169 34.5646C248.607 34.6127 256.473 34.6139 270.425 31.0636C272.596 30.5113 274.87 29.9327 277.322 29.2032C289.029 25.7336 298.8 22.2085 308.126 18.8439L308.129 18.8429L311.364 17.6769C324.976 12.7745 339.109 7.68452 359.201 2.57187L358.924 1.58852C338.739 6.7248 324.469 11.8633 310.794 16.7878L309.809 17.1424C309.115 17.3926 308.419 17.6437 307.721 17.8957C298.402 21.2576 288.645 24.7778 277.045 28.2199C258.842 33.5894 249.653 33.6088 242.229 33.6007C237.246 33.6042 233.041 33.6201 227.356 35.0666C219.502 37.0653 216.547 38.7655 212.746 40.9975C209.406 42.9013 205.681 45.0086 197.794 48.0695C189.079 51.3409 175.696 53.3761 160.266 55.6161C145.043 57.8034 127.828 60.3921 110.568 64.7839C84.6269 71.3849 63.0922 79.0778 47.3098 84.78C45.3796 85.4546 43.537 86.1069 41.7841 86.7274L41.7715 86.7319L41.7629 86.7349L41.7603 86.7359C38.4868 87.8947 35.5254 88.9432 32.8911 89.8191C24.3004 92.7428 21.2526 92.1482 17.7358 91.4621C15.0257 90.887 11.9779 90.2924 6.07916 91.3718C-5.87546 93.4589 -23.9464 101.254 -27.3954 102.742L-27.3992 102.743C-27.7551 102.897 -27.9543 102.983 -27.9737 102.988L-27.4895 103.918C-27.47 103.914 -27.2696 103.827 -26.9115 103.672C-23.4709 102.185 -5.5005 94.4178 6.35662 92.3551C11.9402 91.4488 14.7541 91.9502 17.3802 92.4182L17.5721 92.4523L17.7677 92.4853C21.1859 93.0627 24.5099 93.6242 33.2442 90.6778C37.2951 89.3308 42.0416 87.7015 47.6629 85.6387C63.3419 79.9628 84.98 72.2436 110.818 65.6689C127.112 61.5228 143.299 59.0948 157.844 56.913L157.888 56.9064C158.735 56.7793 159.577 56.6531 160.413 56.5274C175.946 54.2611 189.329 52.2259 198.147 48.9283C206.241 45.8147 210.145 43.5565 213.278 41.7053L213.412 41.6295C217.076 39.557 220.05 37.8743 227.606 35.9516C233.084 34.5578 237.289 34.5418 242.169 34.5646Z"
            fill="white"
          />
          <path
            d="M272.012 35.508C257.956 39.0846 250.098 39.5031 243.64 39.7764L243.355 39.7924C238.677 40.0574 234.582 40.2893 229.112 41.6813C221.361 43.6537 218.351 45.1572 214.57 47.0679C211.381 48.7224 207.393 50.6857 199.347 53.5763C190.399 56.8018 176.968 59.0601 161.311 61.6739L159.872 61.9186C145.224 64.41 128.831 67.1983 112.221 71.4249C86.1757 78.0521 64.4267 85.378 48.4577 90.8116C46.9293 91.3182 45.4574 91.8106 44.0431 92.2836L44.0371 92.2856C40.3919 93.5049 37.1288 94.5964 34.2658 95.4767C25.4129 98.2564 22.2138 97.911 18.7803 97.52L18.5325 97.4934C15.9775 97.2192 13.2178 96.9229 7.73132 98.0127C-4.07799 100.254 -22.2299 107.395 -25.699 108.76C-26.0598 108.902 -26.2618 108.981 -26.2813 108.986L-26.6621 108.029C-26.6427 108.024 -26.4413 107.945 -26.0815 107.804L-26.0804 107.803C-22.6023 106.437 -4.34933 99.2688 7.55721 97.0031C13.4559 95.9237 16.4205 96.2233 18.944 96.5298C22.3775 96.9208 25.3422 97.2204 33.9884 94.4934C36.8513 93.613 40.1144 92.5216 43.7597 91.3023L43.7656 91.3003C45.18 90.8272 46.6519 90.3349 48.1802 89.8282C64.0458 84.421 85.8983 77.0688 111.943 70.4416C128.645 66.1918 145.225 63.3712 159.938 60.8681L161.137 60.6643C176.69 58.0767 190.121 55.8185 198.966 52.6192C206.909 49.7549 210.897 47.7917 213.982 46.1634L213.999 46.155C217.875 44.2221 220.888 42.72 228.731 40.7242C234.416 39.2778 238.565 39.0651 243.493 38.865L243.817 38.848C251.075 38.4665 260.152 37.9895 278.114 32.7958C290.819 29.1415 301.346 25.6197 311.431 22.1049L312.913 21.6045C326.417 17.0444 340.558 12.2692 360.299 7.24615L360.576 8.2295C340.737 13.2775 326.554 18.0752 313.083 22.6319L311.812 23.0619C311.217 23.2629 310.62 23.4646 310.022 23.667L308.981 24.0197C299.728 27.1516 290.019 30.4382 278.495 33.7529C276.249 34.4298 274.079 34.982 272.012 35.508Z"
            fill="white"
          />
          <path
            d="M245.185 44.8635C251.561 44.2952 259.204 43.5097 273.156 39.9594C275.223 39.4334 277.497 38.8549 279.847 38.1517C290.767 35.0994 300.06 32.1463 308.949 29.3212C310.339 28.8794 311.72 28.4404 313.096 28.005L315.607 27.2156C328.977 23.0119 342.984 18.608 362.336 13.6837L362.059 12.7003C341.492 17.9337 326.831 22.5074 312.818 27.0216C302.677 30.3398 292.198 33.6386 279.569 37.1683C261.283 42.2428 252.344 43.1472 245.115 43.8275C240.373 44.2963 236.279 44.7057 230.595 46.1521C222.843 48.1245 219.778 49.4314 215.942 51.1454C212.518 52.7542 208.654 54.3699 200.552 57.0637C191.844 59.8965 179.127 62.3453 164.272 65.2059L163.144 65.4232C148.087 68.2006 131.066 71.4776 113.807 75.8694C87.4517 82.5756 65.385 89.5607 49.2294 94.7255C45.7029 95.8336 42.5313 96.8046 39.6617 97.683L39.6605 97.6834C38.2251 98.1229 36.8653 98.5391 35.5744 98.9379C26.8553 101.575 23.9268 101.491 20.6042 101.396L20.5377 101.394C17.863 101.337 15.1883 101.28 9.31738 102.457C-2.5351 104.9 -20.9348 111.517 -24.4507 112.781L-24.4524 112.782C-24.8171 112.913 -25.0212 112.987 -25.0407 112.992L-24.6599 113.949C-24.6403 113.944 -24.4356 113.87 -24.0698 113.738C-20.5615 112.475 -2.26323 105.885 9.49149 103.467C15.2591 102.316 17.9338 102.373 20.5051 102.456C23.8554 102.552 26.9712 102.603 35.8519 99.9212C38.0178 99.2522 40.3692 98.503 42.9469 97.6816L42.9525 97.6798L42.9553 97.679C44.9832 97.0328 47.1511 96.342 49.4791 95.6106L51.6731 94.9234C67.6337 89.9233 88.9013 83.2607 114.084 76.8528C130.132 72.7694 145.907 69.7574 160.124 67.0431L160.135 67.0409C161.205 66.8367 162.266 66.6341 163.318 66.4329L164.219 66.2593C179.176 63.3789 192.077 60.8943 200.83 58.0471C208.734 55.4268 212.584 53.7401 215.941 52.2697L216.322 52.1025C220.056 50.4148 223.121 49.1078 230.769 47.1617C236.35 45.7416 240.444 45.3323 245.185 44.8635Z"
            fill="white"
          />
          <path
            d="M274.151 44.66C260.996 48.0074 253.506 49.1409 247.486 50.0519L246.684 50.1735C241.998 50.839 237.959 51.445 232.378 52.8651C225.342 54.6554 222.162 55.8216 218.726 57.081C218.427 57.1907 218.126 57.301 217.821 57.4125L216.925 57.7488C213.629 58.9887 209.728 60.4559 202.162 62.7671C193.178 65.4747 180.017 68.2967 164.836 71.4216L163.609 71.6819C148.967 74.7877 132.483 78.2841 115.693 82.5562C90.4754 88.973 68.8901 95.3087 53.1403 99.9487C46.8913 101.75 41.5725 103.314 37.1838 104.641C28.2476 107.126 25.3184 107.344 22.0513 107.543C19.5355 107.656 16.9441 107.894 11.2042 109.144C-0.504461 111.742 -18.9881 117.706 -22.5209 118.846C-22.8883 118.964 -23.0941 119.031 -23.1136 119.036L-23.4944 118.079C-23.475 118.074 -23.2696 118.008 -22.9026 117.889C-19.3619 116.748 -0.776147 110.757 11.0301 108.134C16.2565 106.993 18.9036 106.761 21.2586 106.554C21.5365 106.53 21.8104 106.506 22.0839 106.481C25.2476 106.308 28.1769 106.09 37.0097 103.632C41.4261 102.402 46.7449 100.838 52.9662 98.9391C68.716 94.2991 90.3013 87.9634 115.519 81.5465C132.308 77.2745 148.792 73.7781 163.435 70.6723L164.662 70.412C165.977 70.1323 167.277 69.8563 168.561 69.5839C182.071 66.7163 193.774 64.2324 201.884 61.7838C210.145 59.2603 214.065 57.8413 217.543 56.4292C221.435 54.9118 224.556 53.8016 232.307 51.8292C237.992 50.3828 242.03 49.7768 246.716 49.1113L246.933 49.0786C254.017 48.0105 262.952 46.6632 281.08 41.7374C293.53 38.3586 303.961 35.2828 314.082 32.2859C328.385 28.0141 343.101 23.6371 363.978 18.3248L364.256 19.3082C343.379 24.6205 328.662 28.9975 314.463 33.243C304.342 36.2399 294.014 39.2894 281.461 42.6945C279.978 43.0718 278.62 43.4471 277.347 43.799C276.224 44.1092 275.167 44.4012 274.151 44.66Z"
            fill="white"
          />
          <path
            d="M249.173 54.9643C255.274 53.721 262.771 52.193 276.405 48.7238L282.296 47.2248C295.291 43.8128 306.19 40.8285 316.677 37.9494C330.649 34.0778 345.139 30.0747 365.396 24.9202L365.119 23.9368C344.861 29.0913 330.372 33.0944 316.399 36.9661C305.913 39.8452 295.013 42.8295 282.018 46.2414C263.753 50.9946 254.96 52.8104 247.925 54.179C247.478 54.2727 247.038 54.3647 246.602 54.4558L246.497 54.4776C242.384 55.3372 238.653 56.1168 233.551 57.4149C226.487 59.2125 223.245 60.2123 219.717 61.3007L218.676 61.6215C215.321 62.686 211.139 63.9609 202.926 66.2615C194.665 68.5561 182.922 71.2967 169.381 74.4571L169.366 74.4606C168.094 74.7573 166.807 75.0578 165.505 75.3619C150.746 78.8013 133.919 82.7667 116.763 87.1322C91.5449 93.5491 69.8486 99.4914 54.0156 103.836C47.7388 105.539 42.3923 107.005 37.9759 108.234C29.3285 110.643 26.3258 111.099 23.2757 111.562L23.1888 111.575C20.7285 111.885 18.0892 112.346 12.3771 113.694C0.729405 116.467 -17.9699 121.897 -21.5728 122.944C-21.9513 123.054 -22.1632 123.115 -22.1828 123.12L-21.9054 124.103C-21.8857 124.098 -21.6738 124.037 -21.2954 123.927C-17.6924 122.881 1.00687 117.45 12.6545 114.677C17.5316 113.526 20.1888 113.094 22.3756 112.738L22.377 112.737C22.7509 112.677 23.1111 112.618 23.4663 112.558C23.8178 112.493 24.1683 112.43 24.5269 112.365C27.2825 111.865 30.5165 111.279 38.3567 109.191C42.7731 107.962 48.1196 106.496 54.3964 104.793C70.2294 100.448 91.9258 94.5061 117.144 88.0893C134.404 83.6974 151.127 79.7584 165.886 76.319L169.761 75.4143C183.302 72.2539 195.046 69.5132 203.307 67.2186C211.52 64.918 215.702 63.6431 219.057 62.5786L220.098 62.2578C223.626 61.1694 226.867 60.1695 233.932 58.3719C239.074 57.0635 242.947 56.2569 247.125 55.3868L248.202 55.1624L249.173 54.9643Z"
            fill="white"
          />
          <path
            d="M366.831 29.6139L-20.74 128.233L-20.4626 129.217L367.109 30.5972L366.831 29.6139Z"
            fill="white"
          />
          <path
            d="M-18.3565 134.078C-14.9939 133.335 -0.0736817 130.036 11.2978 127.143L15.8453 125.986C20.6278 124.681 23.092 123.821 25.1787 123.093L25.1798 123.093C25.6059 122.944 26.0163 122.801 26.4275 122.661C26.7829 122.545 27.1349 122.428 27.4948 122.309C30.114 121.441 33.1537 120.435 40.9925 118.533C45.4644 117.5 50.8665 116.231 57.1987 114.725C73.1982 110.97 95.1165 105.815 120.335 99.3978C137.491 95.0322 154.151 90.4768 168.744 86.4474C170.155 86.0587 171.548 85.6747 172.921 85.2964C186.238 81.6252 197.647 78.4801 205.943 76.5603C214.266 74.6531 218.559 73.7715 222.026 73.1003C222.388 73.0269 222.743 72.9551 223.096 72.8838C226.725 72.1511 230.058 71.478 237.123 69.6804C242.367 68.3459 246.157 67.2022 250.239 65.97L251.274 65.6579C258.088 63.5025 266.555 60.9264 284.98 56.3436C298.03 53.1283 309.041 50.5373 319.638 48.0516C333.777 44.7699 348.433 41.3569 368.69 36.2023L368.413 35.219C348.156 40.3735 333.5 43.7866 319.361 47.0682C308.763 49.554 297.752 52.1449 284.702 55.3602C266.278 59.943 257.707 62.5454 250.893 64.7008C250.488 64.823 250.088 64.9439 249.691 65.0638C245.688 66.2725 242.016 67.3812 236.845 68.697C229.78 70.4947 226.447 71.1678 222.818 71.9005C222.466 71.9718 222.11 72.0436 221.748 72.117C218.282 72.7882 213.989 73.6698 205.665 75.577C197.37 77.4967 185.961 80.6417 172.644 84.3127L172.602 84.3244C171.243 84.6991 169.864 85.0792 168.466 85.4641C153.873 89.4935 137.214 94.0488 120.057 98.4144C94.8391 104.831 72.9208 109.987 56.9213 113.742C50.589 115.248 45.187 116.517 40.7151 117.549C31.8547 119.595 29.0136 120.626 26.1282 121.674L26.0467 121.703C23.6495 122.63 21.2245 123.457 15.5679 125.002C3.92478 128.156 -15.058 132.313 -18.7085 133.113L-18.712 133.114C-19.0925 133.197 -19.3054 133.244 -19.325 133.249L-19.0475 134.232C-19.0246 134.226 -18.7906 134.174 -18.3751 134.082L-18.3653 134.08L-18.3628 134.08L-18.3565 134.078Z"
            fill="white"
          />
          <path
            d="M12.5971 132.925C1.22558 135.819 -13.5815 138.591 -16.9187 139.216L-16.9248 139.217C-17.3448 139.295 -17.5815 139.34 -17.6047 139.345L-17.7788 138.336C-17.7597 138.331 -17.5536 138.292 -17.1854 138.223L-17.1656 138.219C-13.5044 137.528 5.64243 133.917 17.1494 130.608C22.7783 128.964 25.1478 127.94 27.3862 126.843C30.3203 125.464 32.972 124.263 42.0192 122.171C46.4911 121.139 51.9209 119.968 58.3086 118.659C74.3913 115.199 96.4207 110.437 121.639 104.02C138.427 99.7477 154.543 94.9488 168.86 90.6859L170.06 90.3284C171.458 89.9136 172.836 89.5037 174.194 89.0997L174.198 89.0986C187.343 85.1892 198.599 81.8414 206.942 80.1006C215.424 78.3637 219.566 77.7314 223.322 77.3026C227.491 76.7687 230.779 76.2484 238.323 74.3286C243.904 72.9085 247.721 71.5158 252.13 69.867L252.334 69.7927C259.031 67.3541 267.479 64.2781 285.875 59.9101C298.436 56.9247 309.089 54.6356 319.432 52.4254C333.965 49.3599 349.117 46.1366 369.891 40.8506L370.168 41.8339C349.291 47.1462 334.242 50.3432 319.606 53.435C309.367 55.6189 298.714 57.908 286.049 60.9198C267.757 65.2614 259.309 68.3371 252.612 70.7756L252.407 70.8503C247.998 72.4992 244.182 73.8919 238.497 75.3383C231.605 77.0921 228.232 77.608 224.525 78.1749C224.214 78.2226 223.899 78.2707 223.581 78.3198L223.289 78.3649L222.337 78.4987C218.829 78.9903 214.678 79.572 206.909 81.1628C197.806 83.0574 184.923 86.8627 170.131 91.3644L168.93 91.7219C154.614 95.9848 138.498 100.784 121.709 105.056C96.4914 111.472 74.4621 116.235 58.3794 119.695C52.0194 121.102 46.5896 122.273 42.0899 123.207C33.2495 125.246 30.4944 126.474 27.6636 127.827C25.3219 128.95 22.9524 129.974 17.2202 131.643C16.5934 131.803 15.9276 131.995 15.2173 132.201L15.2123 132.202C14.4014 132.436 13.5325 132.687 12.5971 132.925Z"
            fill="white"
          />
          <path
            d="M-15.5933 144.276C-12.1894 143.746 2.9111 141.397 14.2823 138.504C15.936 138.083 17.5896 137.662 18.9811 137.097C24.6855 135.33 26.9718 134.01 29.258 132.691L29.3933 132.613C32.2892 130.945 34.8135 129.492 43.5733 127.678C45.8005 127.229 48.2302 126.762 50.8937 126.249L50.9043 126.247C53.001 125.844 55.2427 125.413 57.6445 124.941C74.2516 121.769 97.0398 117.34 123.498 110.608C139.622 106.505 155.028 101.573 168.832 97.1543C169.804 96.8429 170.769 96.5341 171.725 96.2283L172.603 95.949C187.069 91.3427 199.547 87.3698 208.524 85.7055C216.642 84.2483 220.861 83.8812 224.54 83.561L224.967 83.5238C229.192 83.1865 232.535 82.863 240.286 80.8906C245.867 79.4704 249.731 77.8548 254.085 76.0093C260.704 73.1656 268.922 69.7045 287.429 65.4167C298.495 62.8745 308.097 61.0198 317.283 59.2455C318.72 58.9681 320.146 58.6926 321.566 58.4167L323.935 57.9523C337.702 55.2543 352.204 52.4124 371.75 47.4388L371.473 46.4555C350.802 51.7152 335.801 54.6892 321.289 57.4334C310.759 59.3749 299.947 61.4936 287.152 64.4333C268.541 68.7475 260.22 72.2349 253.6 75.0786C249.247 76.924 245.486 78.5134 239.905 79.9335C232.361 81.8533 229.018 82.1769 224.793 82.5142L224.374 82.5506C220.693 82.871 216.471 83.2384 208.246 84.7221C199.736 86.2117 188.302 89.8607 175.033 94.0952C173.818 94.4827 172.589 94.8752 171.345 95.2713C156.843 100.015 140.48 105.233 123.221 109.625C97.814 116.089 75.7916 120.43 59.4011 123.66L57.3948 124.056C52.8 124.872 48.8243 125.678 45.3136 126.39L45.3121 126.39L45.3108 126.391L45.3084 126.391C44.6298 126.529 43.9686 126.663 43.3236 126.793C34.221 128.688 31.5491 130.211 28.6982 131.885C26.412 133.204 24.1258 134.524 18.628 136.239C7.17518 139.726 -12.139 142.707 -15.8435 143.279L-15.8449 143.279C-16.231 143.339 -16.4472 143.372 -16.4667 143.377L-16.2926 144.387C-16.2695 144.381 -16.029 144.343 -15.6023 144.277L-15.5954 144.276L-15.5933 144.276Z"
            fill="white"
          />
          <path
            d="M15.7328 144.037C4.36129 146.931 -10.7675 148.872 -14.1772 149.309C-14.6102 149.365 -14.8542 149.396 -14.8775 149.402L-15.0516 148.392C-15.0322 148.387 -14.8154 148.36 -14.4282 148.312C-10.7048 147.846 8.77477 145.412 20.1818 141.746C25.507 140.084 27.741 138.517 29.8093 137.067L30.01 136.926C32.7776 134.957 35.3662 133.139 44.4966 131.343C47.4396 130.746 50.8402 130.142 54.6391 129.467L54.6403 129.466C56.1157 129.204 57.6513 128.932 59.2434 128.644C75.8304 125.794 98.6262 121.785 124.671 115.158C141.281 110.931 156.946 105.562 170.943 100.765L172.318 100.293C187.151 95.1491 200.074 90.7012 209.523 89.2456C218.046 87.8108 222.558 87.7098 226.182 87.6288L226.183 87.6288L226.236 87.6277L226.293 87.6258C230.546 87.4861 233.845 87.3778 241.459 85.4404C246.929 84.0484 250.598 82.3049 254.788 80.3133L255.043 80.1921L255.246 80.0948C261.749 76.9713 269.954 73.0308 288.4 68.8585C301.327 65.9908 312.298 64.0425 322.883 62.2976L324.619 61.9943C338.65 59.5433 353.271 56.9892 372.923 51.9886L373.201 52.972C353.446 57.9987 338.819 60.5538 324.785 63.0052L323.057 63.3073C322.076 63.4786 321.091 63.6499 320.101 63.8222C310.439 65.5031 300.3 67.267 288.575 69.8682C270.039 74.0576 261.933 77.9121 255.396 81.0508C254.945 81.2629 254.502 81.473 254.063 81.6809C250.22 83.501 246.735 85.1519 241.633 86.45C233.893 88.4195 230.494 88.5489 226.12 88.7155L226.1 88.7163L226.006 88.7195C222.19 88.8524 217.911 89.0014 209.49 90.3079C200.145 91.7371 187.325 96.1587 172.493 101.303L171.347 101.696C157.287 106.514 141.443 111.944 124.742 116.194C98.5936 122.847 75.9012 126.83 59.2108 129.707C57.6187 129.994 56.0831 130.267 54.6077 130.529L54.6066 130.529C50.8076 131.204 47.407 131.808 44.464 132.405C35.5403 134.149 33.1307 135.816 30.363 137.785C28.2356 139.275 25.8259 140.942 20.2526 142.782C19.1434 143.169 17.4898 143.59 15.7328 144.037Z"
            fill="white"
          />
          <path
            d="M-13.4348 154.515C-13.2003 154.561 4.55408 153.1 17.4731 149.812C19.1268 149.392 20.7526 148.872 22.2752 148.38C27.7853 146.463 30.1343 144.549 32.1693 142.891L32.3225 142.767L32.4712 142.648C35.1221 140.529 37.5251 138.609 46.3125 136.993C50.5299 136.236 55.6018 135.367 61.4526 134.511C78.1229 131.956 100.823 128.393 126.764 121.792C143.921 117.426 160.089 111.52 174.349 106.311L174.522 106.247C189.095 100.861 201.769 96.1766 211.132 94.9489C218.952 93.9474 223.458 93.9746 227.007 93.996C227.242 93.9974 227.474 93.9988 227.701 93.9999L227.991 94.0018C232.294 94.0316 235.766 94.0557 243.449 92.1007C249.134 90.6543 252.887 88.6453 257.101 86.3081C263.526 82.7761 271.494 78.4299 290.085 74.4371C301.944 71.9099 312.247 70.3256 322.087 68.8126C322.825 68.6992 323.56 68.5861 324.293 68.4731L325.526 68.2828C339.897 66.0648 354.821 63.7615 375.017 58.6226L374.739 57.6393C354.647 62.7519 339.727 65.0545 325.358 67.2722L324.119 67.4635C323.168 67.611 322.215 67.7581 321.259 67.9058C311.547 69.4059 301.507 70.9566 289.808 73.4538C271.336 77.322 263.26 81.7257 256.777 85.2602L256.514 85.4037C252.299 87.7408 248.753 89.6972 243.172 91.1173C235.616 93.04 232.154 92.995 227.89 92.9396L227.734 92.9376C224.046 92.8221 219.503 92.8187 210.958 93.9392C201.482 95.2964 188.613 99.941 173.865 105.38L173.627 105.467C159.426 110.655 143.445 116.493 126.384 120.835C100.545 127.409 77.8454 130.972 61.1752 133.528C59.1392 133.862 57.1917 134.173 55.3394 134.469C51.8943 135.02 48.7776 135.518 46.0351 136.01C36.7459 137.636 34.2404 139.749 31.5561 142.013C29.5119 143.798 27.3644 145.609 21.8944 147.423C10.5398 151.266 -9.11162 153.072 -12.8742 153.418C-13.2665 153.454 -13.486 153.474 -13.5055 153.479L-13.4348 154.515Z"
            fill="white"
          />
          <path
            d="M-11.4184 159.507L-11.4196 159.507C-11.8547 159.535 -12.0999 159.551 -12.1232 159.557L-12.1939 158.521C-12.1746 158.516 -11.9566 158.503 -11.5673 158.479C-7.79677 158.255 12.0459 157.073 23.2413 152.982C28.5632 151.006 30.6753 149.033 32.6607 147.177L32.7643 147.081C35.3654 144.522 37.8154 142.212 47.1046 140.586C51.2212 139.84 56.07 139.194 61.782 138.434L62.638 138.32C79.2881 136.086 102.099 132.916 127.834 126.368C144.532 122.119 160.105 116.085 174.032 110.688L175.431 110.146C189.993 104.438 202.571 99.5512 212.103 98.3906C220.535 97.3717 225.167 97.7177 228.953 98.0004L229.28 98.0248C233.699 98.3758 237.077 98.5702 244.725 96.6241C250.234 95.2223 253.707 93.1064 257.796 90.6151L257.956 90.5171C264.374 86.5654 272.306 81.7013 291.056 77.8789C303.413 75.3413 314.141 73.8975 324.322 72.5272L325.609 72.3539L326.281 72.2647C340.809 70.3352 355.935 68.3263 376.189 63.1723L376.467 64.1557C356.211 69.31 341.132 71.325 326.572 73.2706L325.68 73.3899C325.002 73.482 324.323 73.5741 323.641 73.6664L323.64 73.6665C313.605 75.0265 303.187 76.4383 291.127 78.9148C272.508 82.8093 264.652 87.5488 258.31 91.3758C258.105 91.4989 257.901 91.6214 257.699 91.7431L257.537 91.8408C253.604 94.2105 250.134 96.3018 244.796 97.66C237.089 99.6213 233.575 99.369 229.09 99.0468L229.013 99.0413L228.592 99.0135C224.745 98.7586 220.341 98.4668 212.07 99.4529C202.746 100.561 190.167 105.448 175.708 111.129L174.536 111.584C160.546 117.004 144.797 123.105 128.008 127.377C102.17 133.952 79.3589 137.122 62.7087 139.356C56.7268 140.14 51.5238 140.937 47.2787 141.596C38.2995 143.143 36.0286 145.302 33.5308 147.834C33.4416 147.918 33.352 148.002 33.2616 148.086C31.3093 149.916 29.0213 152.061 23.6222 153.939C22.2307 154.504 20.4737 154.951 18.6134 155.424C7.24319 158.317 -7.98281 159.288 -11.4172 159.507L-11.4184 159.507Z"
            fill="white"
          />
          <path
            d="M-9.96698 164.64C-6.47665 164.516 9.06441 163.967 20.5327 161.049C22.2895 160.602 23.9435 160.181 25.4381 159.59C31.0594 157.527 33.2471 155.073 35.1803 152.895C37.6982 150.041 39.9137 147.686 48.8172 146.263C51.4899 145.862 54.5611 145.498 57.9769 145.093C59.7224 144.886 61.5578 144.669 63.4759 144.43C80.416 142.439 103.572 139.708 129.824 133.028C146.312 128.833 161.778 122.464 175.468 116.826L177.123 116.145C191.602 110.142 204.201 104.933 213.609 104.12C222.134 103.321 226.712 103.843 230.615 104.325L230.875 104.355C235.33 104.863 238.907 105.271 246.612 103.311C252.296 101.864 255.835 99.4884 260.015 96.6333C266.142 92.4392 273.964 87.1818 292.534 83.5103C305.517 80.8393 316.578 79.6055 327.33 78.4507L327.974 78.3778C342.694 76.7124 357.908 74.9909 378.18 69.8328L377.902 68.8495C357.626 74.0088 342.509 75.7043 327.888 77.3442L327.259 77.4148L325.242 77.6525C315.053 78.8523 304.564 80.0875 292.36 82.5006C273.748 86.1977 265.909 91.455 259.622 95.6719L259.351 95.8535L259.237 95.9294C255.182 98.628 251.658 100.973 246.231 102.354C238.583 104.3 235.15 103.909 230.675 103.361C226.876 102.852 222.063 102.285 213.435 103.111C203.82 103.976 191.221 109.185 176.639 115.214C162.573 121.112 146.6 127.706 129.443 132.071C103.295 138.725 80.1385 141.455 63.1985 143.447C57.3955 144.08 52.5781 144.674 48.4364 145.306C39.2228 146.807 36.7528 149.438 34.2348 152.292C32.3017 154.471 30.2652 156.675 24.8506 158.685C12.4234 163.323 -10.5167 163.68 -10.7512 163.634L-10.6804 164.67C-10.6572 164.664 -10.4083 164.655 -9.96698 164.64Z"
            fill="white"
          />
          <path
            d="M21.9075 166.706C10.4391 169.625 -5.07038 169.668 -8.55343 169.677L-8.55485 169.677C-8.99454 169.678 -9.24218 169.679 -9.26537 169.685L-9.23277 168.623C-8.99832 168.668 14.1637 169.098 26.5077 164.166C31.7583 162.096 33.72 159.615 35.5871 157.253L35.6221 157.209C38.0568 154.06 40.4436 151.134 49.8161 149.803C53.9855 149.269 59.0373 148.722 64.8681 148.186C81.788 146.516 104.952 144.205 130.997 137.578C147.698 133.328 163.174 126.594 176.908 120.617L178.556 119.899C192.831 113.673 205.159 108.295 214.711 107.634C223.395 107.005 228.263 107.769 232.118 108.474L232.178 108.484C236.679 109.224 240.171 109.798 247.785 107.861C253.263 106.467 256.746 103.894 260.766 100.868L260.803 100.841C267.02 96.2058 274.71 90.4723 293.609 86.9256C306.18 84.5463 317.035 83.4998 327.436 82.4971L328.336 82.4103C343.237 81.0428 358.785 79.6159 379.352 74.3825L379.63 75.3658C359.066 80.5983 343.572 82.0376 328.639 83.4247L328.407 83.4463L327.495 83.5342C317.098 84.5366 306.249 85.5824 293.783 87.9353C275.432 91.3588 267.804 96.9644 261.703 101.447L261.43 101.648L260.967 101.991C257.043 104.899 253.633 107.426 248.062 108.844C240.436 110.784 236.802 110.219 232.356 109.527L231.954 109.465C227.996 108.786 223.466 108.041 214.782 108.67C205.483 109.276 193.337 114.574 179.162 120.758L177.518 121.474C163.723 127.476 148.069 134.288 131.274 138.561C105.126 145.215 81.9621 147.526 64.9388 149.222C59.1081 149.757 54.0563 150.305 49.8868 150.839C40.9278 152.065 38.7955 154.715 36.3609 157.864L36.2646 157.986C34.4104 160.333 32.2833 163.025 26.7852 165.149C25.3937 165.714 23.7679 166.233 21.9075 166.706Z"
            fill="white"
          />
          <path
            d="M-7.18377 174.715C-3.70899 174.811 11.969 175.243 23.5924 172.285C25.3494 171.838 27.0786 171.293 28.5457 170.603C34.0302 168.268 36.0928 165.357 37.902 162.803L37.9549 162.729C40.2307 159.409 42.2797 156.464 51.2665 155.336C55.5393 154.776 60.6189 154.327 66.5807 153.864C83.6116 152.586 106.887 150.669 132.932 144.042C150.191 139.65 165.971 132.367 179.973 125.853L180.934 125.411C194.838 119.004 206.828 113.479 216.134 113.069C224.755 112.642 229.361 113.648 233.337 114.516L233.5 114.552L233.748 114.605C238.348 115.594 242.031 116.386 249.747 114.423C255.278 113.015 258.628 110.334 262.465 107.265L262.478 107.254C262.652 107.115 262.827 106.975 263.003 106.834L263.07 106.78C269.095 101.881 276.577 95.7966 295.163 92.4324C308.021 90.109 319.194 89.2686 330.056 88.5072L331.557 88.3881C346.152 87.232 361.33 86.0298 381.315 80.9447L381.037 79.9613C360.47 85.1947 344.969 86.3987 329.985 87.4712C319.123 88.2327 307.847 89.0994 294.885 91.4491C276.197 94.8542 268.599 100.964 262.506 105.864L262.237 106.08C258.272 109.302 254.844 112.072 249.367 113.466C241.839 115.381 238.287 114.651 233.804 113.731L233.588 113.686C229.678 112.784 224.83 111.699 215.987 112.157C206.262 112.63 193.802 118.33 179.414 125.047C165.515 131.535 149.735 138.817 132.578 143.183C106.637 149.784 83.4653 151.675 66.4621 153.051C65.5923 153.118 64.7408 153.183 63.9074 153.247C59.0278 153.621 54.7696 153.946 51.1201 154.425C41.7199 155.658 39.4164 158.879 37.0649 162.323L37.0463 162.349C35.2298 164.913 33.3271 167.598 28.0893 169.771C15.7453 174.704 -7.58325 173.684 -7.8177 173.638L-7.8503 174.7C-7.76856 174.699 -7.5421 174.705 -7.18823 174.715L-7.18594 174.715L-7.18377 174.715Z"
            fill="white"
          />
          <path
            d="M24.995 178.041C12.2827 181.276 -5.60077 179.925 -6.40748 179.814L-6.34714 178.85C-6.11268 178.895 17.4378 180.702 29.5952 175.501C34.8232 173.222 36.6655 170.329 38.4044 167.462C40.6726 163.723 42.8929 160.207 52.452 159.145C56.7526 158.683 61.9632 158.306 68.0561 157.915L68.3357 157.898C85.2957 156.845 108.594 155.399 134.291 148.86C150.992 144.611 166.284 137.224 179.854 130.669L180.96 130.134C195.265 123.122 207.641 117.127 217.422 116.851C226.321 116.59 231.224 117.871 235.19 118.97L235.536 119.055C240.013 120.155 243.62 121.041 250.976 119.169C256.454 117.775 259.826 114.809 263.632 111.417L263.719 111.34C269.733 106.025 277.185 99.4382 296.086 96.0972C309.179 93.8196 320.407 93.1759 331.325 92.611C346.392 91.8336 361.873 90.9509 382.44 85.7175L382.717 86.7008C362.525 91.8388 347.16 92.7313 332.44 93.5862L331.396 93.647C320.478 94.2118 309.25 94.8555 296.26 97.1069C277.689 100.366 270.309 106.817 264.374 112.005L264.267 112.098L263.988 112.347C260.124 115.785 256.802 118.741 251.15 120.179C243.295 122.178 239.488 121.249 234.792 119.915L234.766 119.908C230.704 118.837 226.036 117.607 217.259 117.842C207.684 118.065 195.411 124.033 181.21 131.019C167.29 137.829 151.594 145.406 134.334 149.798C108.672 156.328 85.4835 157.793 68.4467 158.87L67.8924 158.905C67.253 158.944 66.6221 158.982 66.0001 159.019L65.9972 159.02C60.7937 159.333 56.2076 159.609 52.3916 160.109C43.4564 161.129 41.5207 164.199 39.2515 167.799L39.191 167.895C37.4521 170.761 35.4585 173.903 29.9483 176.359C28.5846 177.023 26.8553 177.568 24.995 178.041Z"
            fill="white"
          />
          <path
            d="M-4.82657 184.845C-2.78362 185.05 14.3303 186.763 26.6799 183.62C28.4369 183.173 30.1661 182.628 31.4265 181.991C37.0401 179.508 38.9504 176.071 40.6338 173.008L40.7178 172.859C42.8935 168.986 44.7682 165.65 53.6957 164.731C57.2077 164.354 61.3636 164.164 66.103 163.948C67.1671 163.899 68.2607 163.849 69.3831 163.796L69.7944 163.777C86.9275 162.993 110.283 161.925 135.916 155.403C153.072 151.037 168.761 143.04 182.494 135.963L183.311 135.549C197.209 128.515 209.118 122.489 218.563 122.463C227.414 122.425 232.138 123.857 236.262 125.126C241.118 126.631 244.98 127.756 252.835 125.758C258.446 124.33 261.709 121.221 265.484 117.624L265.841 117.284C271.58 111.713 278.799 104.712 297.536 101.63C310.554 99.4773 321.837 99.0303 332.707 98.6884L333.579 98.6537C348.538 98.0589 364.126 97.4391 384.402 92.2796L384.125 91.2963C363.842 96.4573 348.35 97.0506 333.489 97.6198L332.637 97.6524C321.663 98.0206 310.38 98.4676 297.362 100.621C278.315 103.781 270.993 110.809 265.075 116.53C261.352 120.218 258.035 123.381 252.557 124.774C245.013 126.694 241.385 125.615 236.661 124.182C232.639 122.886 227.681 121.408 218.726 121.473C208.862 121.454 196.569 127.744 182.244 135.078C168.511 142.155 153.029 150.1 135.873 154.465C110.307 160.971 87.0235 162.006 69.9865 162.764L69.9842 162.764L69.6501 162.779C68.7843 162.82 67.9336 162.86 67.0987 162.899L67.0778 162.9C62.0195 163.136 57.5466 163.345 53.8594 163.741C44.2726 164.705 42.1355 168.516 39.9506 172.55C38.2671 175.613 36.4803 178.702 31.2801 181.08C19.1782 186.478 -4.59428 183.884 -4.82873 183.839L-4.99242 184.829C-4.96005 184.832 -4.90611 184.837 -4.83161 184.845L-4.82657 184.845Z"
            fill="white"
          />
          <path
            d="M28.2614 189.225C16.3239 192.263 -0.209918 190.252 -3.15524 189.893C-3.37191 189.867 -3.51504 189.85 -3.5774 189.844L-3.4137 188.854C-3.17925 188.899 20.7874 192.181 32.8339 186.586C38.0063 184.111 39.7099 180.726 41.3378 177.466C43.4396 173.137 45.5212 169.13 55.1357 168.264C58.4799 167.97 62.315 167.851 66.6822 167.715L66.6848 167.715L66.6876 167.715C68.1061 167.671 69.5808 167.625 71.113 167.571L71.3242 167.565C88.4555 167.102 111.9 166.467 137.426 159.972C154.128 155.722 169.235 147.683 182.642 140.549L183.734 139.968L183.881 139.889C197.957 132.32 210.117 125.781 220.003 125.996C228.683 126.123 233.681 127.774 237.748 129.118L238.207 129.269C242.883 130.925 246.67 132.175 254.214 130.255C259.62 128.879 262.767 125.615 266.472 121.771L266.621 121.617L266.737 121.497C272.552 115.501 279.766 108.061 298.714 105.019C311.759 102.965 323.202 102.688 334.231 102.517L334.923 102.504C349.917 102.223 365.527 101.931 385.782 96.7767L386.059 97.76C365.603 102.965 349.837 103.261 334.774 103.544L334.301 103.552C323.273 103.724 311.934 103.974 298.992 106.003C280.227 108.986 273.119 116.38 267.491 122.344C263.645 126.38 260.28 129.765 254.595 131.212C246.741 133.21 242.823 131.889 237.912 130.187L237.745 130.129C233.604 128.676 228.807 126.993 220.046 126.934C210.454 126.765 198.587 133.171 184.726 140.654L184.322 140.872L184.002 141.045C170.329 148.401 154.96 156.67 137.732 161.054C112.137 167.567 88.8044 168.229 71.5971 168.718L71.1082 168.732C69.9977 168.767 68.9117 168.799 67.8521 168.831L67.8436 168.831L67.8424 168.831C63.0393 168.974 58.7784 169.101 55.2342 169.398C46.2675 170.204 44.4127 173.838 42.2833 178.069L42.2422 178.151C40.6244 181.391 38.7814 185.083 33.2424 187.642C31.7476 188.233 30.0184 188.778 28.2614 189.225Z"
            fill="white"
          />
          <path
            d="M-1.88958 194.995C0.472617 195.361 17.6873 198.029 29.8707 194.929C31.731 194.455 33.3292 193.838 34.824 193.247C40.2738 190.62 42.0565 186.697 43.6063 183.287L43.6984 183.084C45.6412 178.585 47.4128 174.656 56.4349 174.046C60.6099 173.812 65.6886 173.754 71.6187 173.685L72.7124 173.673C89.9829 173.48 113.544 173.216 139.21 166.685C156.573 162.267 171.964 153.608 185.634 145.914C186.087 145.662 186.538 145.411 186.987 145.161C200.384 137.706 211.849 131.325 221.274 131.681C230.166 131.936 235.026 133.843 239.247 135.5L239.307 135.524C244.273 137.422 248.246 138.94 256.101 136.942C261.766 135.5 265.044 131.84 268.818 127.627L268.858 127.583L269.058 127.36C274.67 121.113 281.594 113.404 300.192 110.651C313.123 108.614 324.459 108.64 335.452 108.665L335.744 108.666L336.528 108.665C351.676 108.65 367.349 108.634 387.669 103.464L387.391 102.48C366.824 107.714 351.094 107.711 335.805 107.702L335.502 107.701C324.512 107.676 313.178 107.65 300.122 109.615C281.011 112.488 273.934 120.415 268.23 126.804L268.119 126.927L267.891 127.18C264.275 131.179 261.189 134.593 255.824 135.958C248.279 137.878 244.437 136.432 239.705 134.579C235.47 132.917 230.372 130.947 221.307 130.618C211.439 130.21 199.465 136.939 185.601 144.729L185.149 144.983C171.583 152.651 156.192 161.31 139.036 165.676C113.094 172.277 89.4508 172.496 72.0869 172.593L71.793 172.596C65.8465 172.665 60.7532 172.723 56.4675 172.984C46.7975 173.653 44.8269 178.054 42.8084 182.678L42.7476 182.812C41.1936 186.229 39.549 189.846 34.4432 192.29C22.4522 198.081 -1.73643 194.013 -1.97088 193.967L-2.13457 194.957C-2.09515 194.963 -2.01375 194.975 -1.89309 194.994L-1.88958 194.995Z"
            fill="white"
          />
          <path
            d="M31.6867 200.58C18.871 203.841 0.246017 200.254 -0.691784 200.071L-0.424733 199.054C-0.177611 199.102 24.1957 203.855 36.1558 197.967C41.3005 195.393 42.8653 191.517 44.3546 187.765C46.2898 182.846 48.1772 178.15 57.9027 177.678C62.2588 177.413 67.5804 177.429 73.7087 177.556C91.1558 177.753 114.91 177.927 140.748 171.353C157.905 166.987 173.109 158.059 186.592 150.097L187.089 149.805C200.852 141.734 212.83 134.71 222.742 135.312C231.76 135.831 236.926 138.051 241.062 139.829L241.203 139.889C246.253 142.082 249.888 143.581 257.433 141.662C262.763 140.305 265.792 136.74 269.324 132.583L269.618 132.238C275.23 125.434 282.267 117.004 301.426 114.237C314.449 112.39 325.868 112.617 336.946 112.838L337.144 112.842C352.516 113.146 368.433 113.417 389.001 108.184L389.278 109.167C368.8 114.378 353.015 114.093 337.746 113.818L337.084 113.806C326.047 113.558 314.569 113.316 301.496 115.273C282.759 117.933 275.899 126.207 270.363 132.885L270.356 132.893L270.249 133.02C266.559 137.395 263.34 141.213 257.607 142.671C249.799 144.658 245.873 143.019 240.984 140.978L240.702 140.86C236.411 139.001 231.492 136.88 222.606 136.401C212.81 135.837 200.842 142.887 187.076 151.027C173.594 158.99 158.182 167.971 140.922 172.362C114.878 178.99 90.9921 178.744 73.545 178.546C72.1327 178.539 70.7662 178.527 69.4459 178.514L69.4421 178.514C65.0613 178.474 61.1888 178.438 57.8424 178.642C48.9164 179.146 47.2523 183.236 45.325 187.973L45.2445 188.171C43.7553 191.923 42.067 196.147 36.5088 198.826C35.0418 199.515 33.4437 200.132 31.6867 200.58Z"
            fill="white"
          />
          <path
            d="M0.829964 205.063C3.2276 205.566 20.8043 209.251 33.0336 206.139C34.8939 205.665 36.6232 205.12 38.1936 204.404C43.6962 201.529 45.329 197.108 46.7628 193.16L46.8156 193.016C48.6719 187.962 50.2571 183.646 59.2496 183.237C63.6057 182.972 68.9551 183.086 75.1111 183.312C92.6415 183.804 116.638 184.444 142.579 177.843C159.772 173.468 174.918 164.176 188.382 155.916L188.539 155.819L189.626 155.161C202.971 147.077 214.393 140.157 223.986 140.898C232.927 141.573 237.901 143.891 242.248 145.947L242.613 146.112C247.52 148.33 251.498 150.128 259.264 148.152C265.052 146.679 268.223 142.605 271.875 137.881C277.225 130.934 283.972 122.261 302.717 119.599C315.703 117.871 327.205 118.301 338.369 118.719L339.032 118.743C354.416 119.31 370.306 119.896 390.728 114.7L390.451 113.717C370.132 118.887 354.186 118.268 338.831 117.673L338.503 117.66L338.404 117.656C327.24 117.239 315.735 116.809 302.543 118.59C283.402 121.264 276.564 130.044 271.057 137.114L270.788 137.46C267.274 141.98 264.289 145.819 258.883 147.195C251.339 149.114 247.648 147.419 242.543 145.029C238.327 143.045 233.091 140.583 223.915 139.862C213.836 139.09 201.996 146.295 188.199 154.691L186.799 155.542C173.61 163.561 158.822 172.55 142.068 176.814C116.229 183.388 92.364 182.821 74.937 182.302C68.6776 182.103 63.3282 181.989 58.9444 182.155C49.2189 182.628 47.387 187.52 45.535 192.734C44.1013 196.683 42.5919 200.756 37.475 203.428C25.9769 209.441 2.25018 204.298 0.916124 204.009L0.912778 204.009L0.859087 203.997L0.592036 205.014C0.6348 205.022 0.714475 205.039 0.828827 205.063L0.829964 205.063Z"
            fill="white"
          />
          <path
            d="M2.46619 210.224L2.46427 210.224C2.24395 210.173 2.09834 210.139 2.03484 210.127L2.30189 209.111C2.30969 209.112 2.34477 209.121 2.40571 209.135C4.17509 209.564 27.7525 215.271 39.0565 209.033C44.1457 206.262 45.5996 201.993 46.9501 197.75C48.7188 192.241 50.4675 187.053 60.3519 186.751C64.3935 186.618 69.3206 186.824 74.9528 187.059L74.9541 187.059L75.9588 187.101C93.5724 187.889 117.68 188.921 143.725 182.294C160.881 177.929 175.891 168.312 189.208 159.76C202.986 150.879 214.994 143.186 225.267 144.261C234.498 145.179 239.79 147.837 244.192 150.09L244.232 150.11C249.372 152.686 253.119 154.564 260.644 152.649C266.018 151.281 269.058 147.135 272.475 142.366C277.894 134.874 284.681 125.559 303.923 123.087C317.177 121.4 328.762 122.033 340.008 122.648L340.022 122.649C355.561 123.543 371.541 124.43 392.108 119.197L392.385 120.18C371.715 125.44 355.632 124.579 339.989 123.711L339.976 123.71C328.729 123.095 317.144 122.462 303.993 124.123C285.165 126.49 278.784 135.28 273.214 143.021L273.199 143.042C269.609 147.953 266.494 152.214 260.715 153.685C252.756 155.71 248.748 153.673 243.559 150.988C239.158 148.736 234.128 146.222 225.103 145.251C215.299 144.267 203.47 151.809 189.795 160.664L189.23 161.026C175.952 169.515 161.046 179.046 143.926 183.402C117.985 190.003 93.9235 188.968 76.2446 188.208L75.8228 188.189L74.8181 188.148C69.1854 187.912 64.2578 187.707 60.2159 187.84C51.1609 188.152 49.6531 192.694 47.8698 198.066L47.8232 198.206C46.4491 202.339 44.89 207.028 39.4373 209.99C38.2047 210.725 36.5032 211.369 34.6429 211.842C22.6975 214.882 5.50551 210.924 2.46619 210.224Z"
            fill="white"
          />
          <path
            d="M3.44989 215.142C4.41544 215.423 23.4087 220.708 36.2244 217.447C38.0847 216.974 39.7862 216.33 41.2255 215.542C46.602 212.51 48.1003 207.589 49.4102 203.287L49.4618 203.118L49.5325 202.89C51.242 197.379 52.7175 192.622 61.7267 192.409C66.1382 192.34 71.5431 192.651 77.858 193.047C95.5548 194.13 119.67 195.582 145.611 188.981C162.847 184.595 177.757 174.674 191.021 165.848L191.473 165.547C205.024 156.54 216.717 148.769 226.538 149.944C235.563 150.915 240.676 153.725 245.133 156.174C250.377 159.055 254.545 161.262 262.503 159.237C268.291 157.765 271.35 153.297 274.863 148.082C280.322 139.948 286.517 130.888 305.345 128.521C318.07 126.915 329.407 127.732 340.367 128.521L341.48 128.602L341.627 128.613C357.204 129.799 373.361 131.029 393.967 125.785L393.69 124.802C373.226 130.009 357.162 128.827 341.54 127.638C330.206 126.727 318.459 125.922 305.171 127.512C285.874 129.787 279.226 139.594 273.918 147.479C270.556 152.445 267.572 156.788 262.198 158.156C254.728 160.056 250.928 158.028 245.791 155.287L245.635 155.203L245.301 155.026C240.935 152.706 235.598 149.871 226.543 148.784C216.243 147.611 204.29 155.5 190.595 164.676L190.332 164.852C177.164 173.657 162.349 183.563 145.306 187.899C119.571 194.448 95.4563 192.995 77.8628 191.886C71.5479 191.491 66.1431 191.179 61.6282 191.274C51.7161 191.478 50.0507 196.961 48.3651 202.765L48.3463 202.826C47.0293 207.15 45.6477 211.684 40.6103 214.54C29.3961 220.838 5.8658 214.687 3.85318 214.161C3.77216 214.139 3.72601 214.127 3.71694 214.126L3.44989 215.142Z"
            fill="white"
          />
          <path
            d="M37.9094 223.026C25.0937 226.287 5.83058 220.438 4.96839 220.131L5.26318 219.213C5.27361 219.215 5.3333 219.232 5.43887 219.262C7.70592 219.918 31.1307 226.69 42.323 220.217C47.3567 217.25 48.6719 212.488 49.9114 207.852C51.5137 201.753 53.1236 196.073 63.0634 195.968C67.475 195.9 72.8042 196.335 79.0435 196.855L79.436 196.886C97.1796 198.261 121.268 200.129 147.019 193.576C164.072 189.237 178.991 178.906 192.141 169.763L192.191 169.729C205.884 160.246 217.635 152.109 227.978 153.478C237.173 154.768 242.579 157.84 247.023 160.365L247.237 160.487C252.536 163.565 256.366 165.752 263.807 163.859C269.181 162.491 272.11 157.952 275.389 152.691L275.448 152.599C280.662 144.434 287.195 134.204 306.448 132.035C319.735 130.446 331.566 131.546 343.059 132.626C358.764 134.111 374.911 135.588 395.374 130.381L395.652 131.364C375.013 136.616 358.754 135.079 343.07 133.597L342.998 133.59C331.609 132.483 319.909 131.455 306.594 132.946C287.71 135.117 281.654 144.668 276.306 153.195C272.849 158.607 269.844 163.271 264.057 164.744C256.099 166.769 251.876 164.365 246.576 161.287C242.063 158.641 236.895 155.635 227.815 154.468C217.947 153.098 206.348 161.146 192.997 170.41L192.805 170.543L192.163 170.987C179.071 180.047 164.38 190.213 147.296 194.56C121.252 201.187 96.791 199.296 79.0109 197.918C72.7716 197.397 67.4424 196.962 63.1342 197.004C54.0766 197.096 52.6381 202.204 51.008 208.205C49.7685 212.842 48.3577 218.049 42.9105 221.121C41.3679 221.935 39.6664 222.579 37.9094 223.026Z"
            fill="white"
          />
          <path
            d="M6.30774 225.271C7.19768 225.677 26.5995 232.017 39.4152 228.756C41.2756 228.283 42.977 227.639 44.4164 226.851C49.808 223.582 51.1633 218.178 52.3474 213.345L52.3564 213.309C53.9271 207.127 55.2947 201.744 64.3903 201.849C68.7263 201.905 74.0555 202.341 80.3225 202.959C98.1859 204.632 122.757 206.917 148.802 200.289C165.745 195.978 180.399 185.445 193.389 176.107L194.116 175.585L194.504 175.305C207.69 165.807 219.142 157.558 229.015 159.116C238.179 160.578 243.403 163.781 247.971 166.624C253.326 169.899 257.604 172.499 265.563 170.474C271.35 169.001 274.299 164.14 277.673 158.433C282.938 149.611 288.883 139.666 307.739 137.398C320.62 136.069 332.122 137.285 343.25 138.462L344.179 138.56L345.033 138.649C360.631 140.278 376.775 141.964 397.075 136.799L396.797 135.816C376.698 140.93 360.657 139.217 345.159 137.561L344.315 137.471L343.816 137.419C332.552 136.229 320.817 134.99 307.668 136.362C288.213 138.467 281.814 149.159 276.7 157.733C273.477 163.19 270.604 167.926 265.23 169.294C257.798 171.185 253.913 168.809 248.568 165.541L248.548 165.528L248.205 165.321C243.729 162.613 238.259 159.304 229.123 157.929C218.712 156.363 206.926 164.842 193.398 174.608L193.03 174.874C180.055 184.246 165.465 194.785 148.469 199.109C122.631 205.684 98.2941 203.445 80.4307 201.772C79.3463 201.682 78.2885 201.591 77.2575 201.502C72.357 201.082 68.0629 200.713 64.3952 200.688C54.3998 200.597 52.8731 206.571 51.3541 212.965L51.3485 212.988C50.1662 217.814 48.9303 222.859 43.9044 225.822C32.8095 232.457 9.35289 225.149 6.91093 224.389L6.90898 224.388C6.78675 224.35 6.71712 224.328 6.70589 224.326L6.30774 225.271Z"
            fill="white"
          />
          <path
            d="M41.1001 234.335C28.181 237.622 8.71608 230.665 7.72279 230.286L8.12093 229.341C8.38313 229.485 33.8835 238.595 45.486 231.427C50.4919 228.361 51.6683 223.109 52.7691 217.98C54.2049 211.291 55.6484 205.022 65.6992 205.31C70.1385 205.34 75.6266 205.946 81.9214 206.663C99.7646 208.657 124.344 211.361 150.182 204.787C167.235 200.447 181.96 189.428 194.944 179.695L195.403 179.354C208.754 169.411 220.21 160.881 230.586 162.721C239.511 164.242 245.003 167.676 249.439 170.45L249.451 170.457C249.696 170.61 249.938 170.762 250.178 170.91L250.303 170.991C255.649 174.422 259.586 176.948 266.97 175.069C272.344 173.702 275.162 168.769 278.33 163.115C283.332 154.148 289.62 143.062 309.104 141.056C321.922 139.718 333.473 141.134 344.534 142.491L344.597 142.498C345.028 142.551 345.459 142.604 345.889 142.657L346.089 142.682C361.87 144.65 378.132 146.678 398.51 141.493L398.787 142.476C378.448 147.651 362.201 145.663 346.493 143.741L345.725 143.647C334.225 142.147 322.386 140.627 309.147 141.993C290.211 143.865 284.392 154.27 279.245 163.475L279.22 163.521L279.154 163.638C275.863 169.492 272.997 174.59 267.247 176.053C259.289 178.078 254.955 175.281 249.517 171.711L249.488 171.692C244.871 168.657 239.567 165.171 230.395 163.613C220.377 161.844 208.966 170.427 195.638 180.451L195.607 180.475L195.126 180.834C182.157 190.506 167.508 201.432 150.459 205.77C124.439 212.391 99.8824 209.698 81.9404 207.731L81.8888 207.725C75.594 207.008 70.1059 206.402 65.77 206.346C56.6292 206.142 55.3293 211.743 53.9414 218.209L53.9366 218.23C52.8093 223.255 51.5332 228.942 46.1768 232.305C44.5586 233.244 42.8571 233.888 41.1001 234.335Z"
            fill="white"
          />
          <path
            d="M23.8555 239.566C31.489 241.101 37.8518 241.274 42.606 240.064C44.4663 239.591 46.1401 238.849 47.4483 237.989C52.7652 234.651 53.9799 228.733 55.0459 223.539L55.0741 223.401C56.3511 216.542 57.5954 210.745 66.764 211.046C70.449 211.177 74.8819 211.718 79.9003 212.331C80.8245 212.444 81.7686 212.559 82.7316 212.675L83.3143 212.747C101.395 214.978 125.965 218.01 151.834 211.427C168.981 207.064 183.619 195.746 196.56 185.74L196.815 185.543L197.456 185.049C210.466 175.028 221.601 166.451 231.52 168.386C240.767 170.143 246.13 173.838 250.809 177.073C256.302 180.84 260.692 183.833 268.65 181.808C274.48 180.325 277.293 175.067 280.514 169.046L280.614 168.857L280.68 168.735C285.734 159.253 291.462 148.509 310.375 146.74C323.642 145.472 335.536 147.188 347.092 148.885L347.781 148.981C363.597 151.198 379.946 153.488 400.321 148.304L400.043 147.321C379.641 152.512 363.282 150.18 347.4 147.915L346.571 147.796C335.125 146.155 323.31 144.461 310.201 145.73C290.662 147.54 284.513 159.117 279.593 168.379C276.509 174.328 273.747 179.458 268.372 180.825C260.931 182.719 256.963 180.04 251.469 176.273C251.351 176.193 251.231 176.113 251.111 176.032C246.527 172.936 240.923 169.153 231.684 167.395C221.298 165.369 209.975 174.102 196.78 184.279L196.152 184.763C183.252 194.791 168.61 206.105 151.557 210.444C125.856 216.984 101.357 213.908 83.3718 211.65L82.8675 211.586C81.9046 211.471 80.9605 211.355 80.0362 211.242C75.0179 210.63 70.5849 210.088 66.8999 209.958C56.8213 209.571 55.4611 216.136 54.1085 223.12L54.0752 223.283C53.0124 228.461 51.8907 233.927 46.9641 237.058C35.5482 244.495 9.82589 234.599 9.56369 234.455L9.16555 235.399C9.16555 235.399 15.8842 238.011 23.8555 239.566Z"
            fill="white"
          />
        </svg>
      )}
    </Box>
  );
}

export default GamecenterPlayerCardHeaderBackground;
