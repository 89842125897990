import { Group, Loader, Table, createStyles } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { Text, textStyles } from '~/domains/design-system/Text';
import { useInfiniteScroll } from '~/hooks/useInfiniteScroll';

const TABLE_CELL_WIDTH = '150px';

const useStyles = createStyles((theme) => ({
  wrapper: {
    overflow: 'auto',
  },
  table: {
    position: 'relative',
    '& thead': {
      position: 'sticky',
      background: theme.white,
      top: 0,
    },
    '& tbody': {
      background: theme.white,
    },
    '& thead tr th': {
      width: TABLE_CELL_WIDTH,
      textAlign: 'center',
      textTransform: 'uppercase',
      color: theme.colors.gray2[6],
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
      whiteSpace: 'nowrap',
      ...textStyles['subhead-x-small'],
    },
    '& tbody tr td': {
      fontSize: '10px',
      textAlign: 'center',
      color: theme.colors.gray2[6],
      whiteSpace: 'nowrap',
      ...textStyles['body-small'],
    },
  },
  tableData: {
    width: TABLE_CELL_WIDTH,
    fontSize: '10px',
    textAlign: 'center',
    color: theme.colors.dark[5],
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    padding: theme.spacing.xs,
  },
  tableWrapper: {
    overflowX: 'auto',
    flexShrink: 0,
    paddingBottom: theme.spacing.md,
    marginTop: theme.other.spacing._8,
  },
}));

export type PlayerGamesLogTableColumn = {
  label: string;
  values: (string | JSX.Element)[];
};

export type PlayerGamesLogTable = PlayerGamesLogTableColumn[];

type GamecenterPlayerDetailGameLogProps = {
  tableData: PlayerGamesLogTable;
  tableFetchNextPage?: () => void;
  tableHasNextPage?: boolean;
  isTableFetchingNextPage?: boolean;
};

function GamecenterPlayerDetailGameLog({
  tableData,
  tableFetchNextPage,
  tableHasNextPage,
  isTableFetchingNextPage,
}: GamecenterPlayerDetailGameLogProps) {
  const { classes, theme } = useStyles();
  const { t } = useTranslation('contest');

  const { loaderRef } = useInfiniteScroll({
    fetchMore: tableFetchNextPage,
    hasMore: tableHasNextPage,
  });

  if (!tableData || tableData.length === 0) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <Text variant="headline-medium" color={theme.colors.gray2[6]}>
        {t('game.dfs.playerDetail.logTitle')}
      </Text>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <thead>
            <tr>
              {tableData.map(({ label }) => (
                <th key={label}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.length > 0 &&
              tableData[0].values?.map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={index}>
                  {tableData?.map((column) => (
                    <td className={classes.tableData} key={column.label}>
                      {column.values[index] ? column.values[index] : '-'}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
          <div ref={loaderRef} />
        </Table>
        {isTableFetchingNextPage && (
          <Group position="center" py="md">
            <Loader size={16} />
          </Group>
        )}
      </div>
    </div>
  );
}

export default GamecenterPlayerDetailGameLog;
