import type { ContestSettings } from '@betterpool/api-types/contests-service';

import type { PlayerDetail } from '~/domains/games/interfaces/player-detail.interface';

import type { PlayerGroupedStats } from '../../components/GamecenterPlayerDetail/GamecenterPlayerDetailStats';
import formatEarnings from '../../utils/formatEarnings';

const round = (value?: number) => {
  if (typeof value !== 'number') {
    return '-';
  }

  return Math.round(value);
};

const parseGolf = (
  playerData: PlayerDetail,
  scoreType?: ContestSettings['scoreType']
): PlayerGroupedStats => ({
  name: 'Season Tournament Stats',
  orderedStats: [
    scoreType === 'dollars' && {
      name: 'EARNINGS',
      value: formatEarnings(playerData.attributes?.earnings),
    },
    {
      name: 'WINS',
      value: round(playerData.attributes?.first_place),
    },
    {
      name: 'Top 10s',
      value: round(playerData.attributes?.top_10),
    },
    {
      name: 'Top 25s',
      value: round(playerData.attributes?.top_25),
    },
    {
      name: 'AVG SCR',
      value: round(playerData.attributes?.scoring_avg),
    },
    {
      name: 'Missed Cuts',
      value: round(playerData.attributes?.cuts),
    },
    {
      name: 'BIRDIES PER RD',
      value: round(playerData.attributes?.birdies_per_round),
    },
    {
      name: 'HOLES PER EAGLE',
      value: round(playerData.attributes?.holes_per_eagle),
    },
  ].filter(Boolean),
});

export default parseGolf;
