import { CurrencyConvertor } from '~/components/utils/formatters';

const formatEarnings = (earnings?: number) => {
  if (!earnings) {
    return '-';
  }

  if (earnings >= 1_000_000) {
    return `${CurrencyConvertor((earnings / 1_000_000).toFixed(2))}m`.replace('US', '');
  }
  if (earnings >= 100_000) {
    return `${CurrencyConvertor((earnings / 1_000).toFixed(2))}k`.replace('US', '');
  }
  return CurrencyConvertor(earnings).replace('US', '');
};

export default formatEarnings;
