import type { PlayerDetail } from '~/domains/games/interfaces/player-detail.interface';

import type { PlayerGroupedStats } from '../../components/GamecenterPlayerDetail/GamecenterPlayerDetailStats';

const parseBasketball = (playerData: PlayerDetail): PlayerGroupedStats => ({
  name: 'Season averages',
  orderedStats: [
    {
      name: 'MIN',
      value: playerData.attributes?.average?.minutes ?? '-',
    },
    {
      name: 'PTS',
      value: playerData.attributes?.average?.points ?? '-',
    },
    {
      name: 'REB',
      value: playerData.attributes?.average?.rebounds ?? '-',
    },
    {
      name: 'AST',
      value: playerData.attributes?.average?.assists ?? '-',
    },
    {
      name: 'STL',
      value: playerData.attributes?.average?.steals ?? '-',
    },
    {
      name: 'BLK',
      value: playerData.attributes?.average?.blocks ?? '-',
    },
  ],
});

export default parseBasketball;
