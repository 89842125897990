import { Box, Group, Stack, createStyles } from '@mantine/core';
import ReactCountryFlag from 'react-country-flag';

import { countryToCountryCode } from '~/common/constants/countries.const';
import ProfileImage from '~/components/shared/Images/ProfileImage';
import { SplitFullNameToFirstLast } from '~/components/utils/formatters';
import type { PlayerInfoTag } from '~/domains/contest/domains/game/domains/common/dfs/types/player';
import { Text } from '~/domains/design-system/Text';

import HeaderBackground from './GamecenterPlayerDetailHeaderBackground';
import PlayerCardTag from './GamecenterPlayerDetailTag';
import GamecenterPlayerCardTeamColor from './GamecenterPlayerDetailTeamColor';

const useStyles = createStyles((theme) => ({
  wrapper: {
    background: theme.colors.gray[1],
    padding: theme.other.spacing._16,
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
    minHeight: 140,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    width: '90%',
    height: '100%',
    position: 'relative',
    zIndex: 1,
    flexGrow: 1,
  },
  tag: {
    zIndex: 4,
    minWidth: 36,
    minHeight: 24,
    width: 'auto',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 4px 2px 4px',
  },
  teamAliasWrapper: {
    position: 'absolute',
    top: theme.other.spacing._4,
    right: 100,
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
  teamAlias: {
    fontSize: '54px',
    lineHeight: '48px',
    fontStyle: 'italic',
    WebkitTextStroke: '2px',
    WebkitTextFillColor: theme.colors.gray[1],
    fontFamily: theme.other.fontFamily.hubotSansBlack,
  },
  teamImage: {
    alignSelf: 'end',
  },
}));

type GamecenterPlayerDetailHeaderProps = {
  playerImage?: string;
  playerName: string;
  tag?: PlayerInfoTag;
  leagueImage?: string;
  useTeamAlias?: boolean;
  teamAlias?: string;
  teamColor?: string;
};

function GamecenterPlayerDetailHeader({
  useTeamAlias,
  teamAlias,
  playerImage,
  playerName,
  tag = {},
  leagueImage,
  teamColor,
}: GamecenterPlayerDetailHeaderProps) {
  const { classes, theme } = useStyles();

  const { firstName, lastName } = SplitFullNameToFirstLast(playerName);

  const stats: (JSX.Element | string)[] = [];
  if (tag.position) stats.push(tag.position);
  if (tag.team) stats.push(tag.team);
  if (tag.rank) stats.push(tag.rank);
  if (tag.jerseyNumber) stats.push(tag.jerseyNumber);
  if (tag.country) {
    stats.push(tag.country);
    stats.push(
      <ReactCountryFlag
        countryCode={countryToCountryCode[tag.country]}
        svg
        style={{
          height: 14,
          width: 20,
        }}
      />
    );
  }

  return (
    <Box className={classes.wrapper}>
      <Group className={classes.contentWrapper} position="apart" align="center" noWrap>
        <Stack spacing={0}>
          <Text component="span" variant="title-medium" color={theme.colors.gray2[6]}>
            {firstName}
          </Text>
          <Text component="span" variant="headline-xxl" color={theme.colors.gray2[6]}>
            {lastName}
          </Text>
          <Group spacing={4}>
            {stats.map((stat, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Text component="span" variant="body-medium" color={theme.colors.gray2[4]} key={index}>
                {stat}
                {index !== stats.length - 1 ? ' • ' : null}
              </Text>
            ))}
          </Group>
        </Stack>
        <ProfileImage
          alt="Player Image"
          className={classes.teamImage}
          height={124}
          width={124}
          src={playerImage}
        />
      </Group>
      <PlayerCardTag className={classes.tag} tag={{ ...tag, position: undefined }} />
      {useTeamAlias ? (
        <div className={classes.teamAliasWrapper}>
          <Text className={classes.teamAlias} color={teamColor} opacity={0.1}>
            {teamAlias}
          </Text>
          <Text className={classes.teamAlias} color={teamColor} opacity={0.075}>
            {teamAlias}
          </Text>
          <Text className={classes.teamAlias} color={teamColor} opacity={0.05}>
            {teamAlias}
          </Text>
        </div>
      ) : (
        <HeaderBackground image={leagueImage} />
      )}
      {teamColor ? <GamecenterPlayerCardTeamColor fill={teamColor} /> : null}
    </Box>
  );
}

export default GamecenterPlayerDetailHeader;
