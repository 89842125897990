import type { PlayerDetail } from '~/domains/games/interfaces/player-detail.interface';

import type { PlayerGroupedStat } from '../../../components/GamecenterPlayerDetail/GamecenterPlayerDetailStats';

const parseWRorTE = (playerDetail: PlayerDetail): PlayerGroupedStat[] => [
  {
    name: 'TAR',
    value: playerDetail.attributes?.receiving?.targets ?? '-',
  },
  {
    name: 'REC',
    value: playerDetail.attributes?.receiving?.receptions ?? '-',
  },
  {
    name: 'YDS',
    value: playerDetail.attributes?.receiving?.yards ?? '-',
  },
  {
    name: 'TD',
    value: playerDetail.attributes?.receiving?.touchdowns ?? '-',
  },
];

export default parseWRorTE;
