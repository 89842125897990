import type { PlayerDetail } from '~/domains/games/interfaces/player-detail.interface';

import type {
  PlayerGroupedStat,
  PlayerGroupedStats,
} from '../../../components/GamecenterPlayerDetail/GamecenterPlayerDetailStats';

import parseQB from './parseQB';
import parseRB from './parseRB';
import parseWRorTE from './parseWRorTE';

const parsers: {
  [position: string]: (playerData: PlayerDetail) => PlayerGroupedStat[];
} = {
  QB: parseQB,
  RB: parseRB,
  TE: parseWRorTE,
  WR: parseWRorTE,
};

const parseFootballGames = (playerData: PlayerDetail): PlayerGroupedStats => {
  const position = playerData.attributes?.position;
  if (!position) return;

  const groupedStats = parsers[position] ?? (() => []);

  return {
    name: 'Season totals',
    orderedStats: groupedStats(playerData),
  };
};

export default parseFootballGames;
