export const countryToCountryCode = {
  ANDORRA: 'AD',
  'UNITED ARAB EMIRATES': 'AE',
  AFGHANISTAN: 'AF',
  'ANTIGUA AND BARBUDA': 'AG',
  ANGUILLA: 'AI',
  ALBANIA: 'AL',
  ARMENIA: 'AM',
  'NETHERLANDS ANTILLES': 'AN',
  ANGOLA: 'AO',
  ANTARCTICA: 'AQ',
  ARGENTINA: 'AR',
  'AMERICAN SAMOA': 'AS',
  AUSTRIA: 'AT',
  AUSTRALIA: 'AU',
  ARUBA: 'AW',
  'ALAND ISLANDS': 'AX',
  AZERBAIJAN: 'AZ',
  'BOSNIA AND HERZEGOVINA': 'BA',
  BARBADOS: 'BB',
  BANGLADESH: 'BD',
  BELGIUM: 'BE',
  'BURKINA FASO': 'BF',
  BULGARIA: 'BG',
  BAHRAIN: 'BH',
  BURUNDI: 'BI',
  BENIN: 'BJ',
  'SAINT BARTHELEMY': 'BL',
  BERMUDA: 'BM',
  'BRUNEI DARUSSALAM': 'BN',
  'BOLIVIA, PLURINATIONAL STATE OF': 'BO',
  'BONAIRE, SAINT EUSTATIUS AND SABA': 'BQ',
  BRAZIL: 'BR',
  BAHAMAS: 'BS',
  BHUTAN: 'BT',
  'BOUVET ISLAND': 'BV',
  BOTSWANA: 'BW',
  BELARUS: 'BY',
  BELIZE: 'BZ',
  CANADA: 'CA',
  'COCOS (KEELING) ISLANDS': 'CC',
  'CONGO, THE DEMOCRATIC REPUBLIC OF THE': 'CD',
  'CENTRAL AFRICAN REPUBLIC': 'CF',
  CONGO: 'CG',
  SWITZERLAND: 'CH',
  "COTE D'IVOIRE": 'CI',
  'COOK ISLANDS': 'CK',
  CHILE: 'CL',
  CAMEROON: 'CM',
  CHINA: 'CN',
  COLOMBIA: 'CO',
  'COSTA RICA': 'CR',
  CUBA: 'CU',
  'CAPE VERDE': 'CV',
  CURACAO: 'CW',
  'CHRISTMAS ISLAND': 'CX',
  CYPRUS: 'CY',
  'CZECH REPUBLIC': 'CZ',
  GERMANY: 'DE',
  DJIBOUTI: 'DJ',
  DENMARK: 'DK',
  DOMINICA: 'DM',
  'DOMINICAN REPUBLIC': 'DO',
  ALGERIA: 'DZ',
  ECUADOR: 'EC',
  ESTONIA: 'EE',
  EGYPT: 'EG',
  'WESTERN SAHARA': 'EH',
  ERITREA: 'ER',
  SPAIN: 'ES',
  ETHIOPIA: 'ET',
  FINLAND: 'FI',
  FIJI: 'FJ',
  'FALKLAND ISLANDS (MALVINAS)': 'FK',
  'MICRONESIA, FEDERATED STATES OF': 'FM',
  'FAROE ISLANDS': 'FO',
  FRANCE: 'FR',
  GABON: 'GA',
  ENGLAND: 'GB',
  GRENADA: 'GD',
  GEORGIA: 'GE',
  'FRENCH GUIANA': 'GF',
  GUERNSEY: 'GG',
  GHANA: 'GH',
  GIBRALTAR: 'GI',
  GREENLAND: 'GL',
  GAMBIA: 'GM',
  GUINEA: 'GN',
  GUADELOUPE: 'GP',
  'EQUATORIAL GUINEA': 'GQ',
  GREECE: 'GR',
  'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS': 'GS',
  GUATEMALA: 'GT',
  GUAM: 'GU',
  'GUINEA-BISSAU': 'GW',
  GUYANA: 'GY',
  'HONG KONG': 'HK',
  'HEARD ISLAND AND MCDONALD ISLANDS': 'HM',
  HONDURAS: 'HN',
  CROATIA: 'HR',
  HAITI: 'HT',
  HUNGARY: 'HU',
  INDONESIA: 'ID',
  'NORTHERN IRELAND': 'GB-NIR',
  IRELAND: 'IE',
  ISRAEL: 'IL',
  'ISLE OF MAN': 'IM',
  INDIA: 'IN',
  'BRITISH INDIAN OCEAN TERRITORY': 'IO',
  IRAQ: 'IQ',
  'IRAN, ISLAMIC REPUBLIC OF': 'IR',
  ICELAND: 'IS',
  ITALY: 'IT',
  JERSEY: 'JE',
  JAMAICA: 'JM',
  JORDAN: 'JO',
  JAPAN: 'JP',
  KENYA: 'KE',
  KYRGYZSTAN: 'KG',
  CAMBODIA: 'KH',
  KIRIBATI: 'KI',
  COMOROS: 'KM',
  'SAINT KITTS AND NEVIS': 'KN',
  "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF": 'KP',
  'SOUTH KOREA': 'KR',
  KUWAIT: 'KW',
  'CAYMAN ISLANDS': 'KY',
  KAZAKHSTAN: 'KZ',
  "LAO PEOPLE'S DEMOCRATIC REPUBLIC": 'LA',
  LEBANON: 'LB',
  'SAINT LUCIA': 'LC',
  LIECHTENSTEIN: 'LI',
  'SRI LANKA': 'LK',
  LIBERIA: 'LR',
  LESOTHO: 'LS',
  LITHUANIA: 'LT',
  LUXEMBOURG: 'LU',
  LATVIA: 'LV',
  LIBYA: 'LY',
  MOROCCO: 'MA',
  MONACO: 'MC',
  'MOLDOVA, REPUBLIC OF': 'MD',
  MONTENEGRO: 'ME',
  'SAINT MARTIN (FRENCH PART)': 'MF',
  MADAGASCAR: 'MG',
  'MARSHALL ISLANDS': 'MH',
  'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF': 'MK',
  MALI: 'ML',
  MYANMAR: 'MM',
  MONGOLIA: 'MN',
  MACAO: 'MO',
  'NORTHERN MARIANA ISLANDS': 'MP',
  MARTINIQUE: 'MQ',
  MAURITANIA: 'MR',
  MONTSERRAT: 'MS',
  MALTA: 'MT',
  MAURITIUS: 'MU',
  MALDIVES: 'MV',
  MALAWI: 'MW',
  MEXICO: 'MX',
  MALAYSIA: 'MY',
  MOZAMBIQUE: 'MZ',
  NAMIBIA: 'NA',
  'NEW CALEDONIA': 'NC',
  NIGER: 'NE',
  'NORFOLK ISLAND': 'NF',
  NIGERIA: 'NG',
  NICARAGUA: 'NI',
  NETHERLANDS: 'NL',
  NORWAY: 'NO',
  NEPAL: 'NP',
  NAURU: 'NR',
  NIUE: 'NU',
  'NEW ZEALAND': 'NZ',
  OMAN: 'OM',
  PANAMA: 'PA',
  PERU: 'PE',
  'FRENCH POLYNESIA': 'PF',
  'PAPUA NEW GUINEA': 'PG',
  PHILIPPINES: 'PH',
  PAKISTAN: 'PK',
  POLAND: 'PL',
  'SAINT PIERRE AND MIQUELON': 'PM',
  PITCAIRN: 'PN',
  'PUERTO RICO': 'PR',
  'PALESTINIAN TERRITORY, OCCUPIED': 'PS',
  PORTUGAL: 'PT',
  PALAU: 'PW',
  PARAGUAY: 'PY',
  QATAR: 'QA',
  REUNION: 'RE',
  ROMANIA: 'RO',
  SERBIA: 'RS',
  'RUSSIAN FEDERATION': 'RU',
  RWANDA: 'RW',
  'SAUDI ARABIA': 'SA',
  'SOLOMON ISLANDS': 'SB',
  SEYCHELLES: 'SC',
  SUDAN: 'SD',
  SWEDEN: 'SE',
  SINGAPORE: 'SG',
  'SAINT HELENA': 'SH',
  SLOVENIA: 'SI',
  'SVALBARD AND JAN MAYEN': 'SJ',
  SLOVAKIA: 'SK',
  'SIERRA LEONE': 'SL',
  'SAN MARINO': 'SM',
  SENEGAL: 'SN',
  SOMALIA: 'SO',
  SURINAME: 'SR',
  SCOTLAND: 'GB-SCT',
  'SOUTH SUDAN': 'SS',
  'SAO TOME AND PRINCIPE': 'ST',
  'EL SALVADOR': 'SV',
  'SINT MAARTEN (DUTCH PART)': 'SX',
  'SYRIAN ARAB REPUBLIC': 'SY',
  SWAZILAND: 'SZ',
  'TURKS AND CAICOS ISLANDS': 'TC',
  CHAD: 'TD',
  'FRENCH SOUTHERN TERRITORIES': 'TF',
  TOGO: 'TG',
  THAILAND: 'TH',
  TAJIKISTAN: 'TJ',
  TOKELAU: 'TK',
  'TIMOR-LESTE': 'TL',
  TURKMENISTAN: 'TM',
  TUNISIA: 'TN',
  TONGA: 'TO',
  TURKEY: 'TR',
  'TRINIDAD AND TOBAGO': 'TT',
  TUVALU: 'TV',
  'CHINESE TAIPEI': 'TW',
  'TANZANIA, UNITED REPUBLIC OF': 'TZ',
  UKRAINE: 'UA',
  UGANDA: 'UG',
  'UNITED STATES MINOR OUTLYING ISLANDS': 'UM',
  'UNITED STATES': 'US',
  URUGUAY: 'UY',
  UZBEKISTAN: 'UZ',
  'HOLY SEE (VATICAN CITY STATE)': 'VA',
  'SAINT VINCENT AND THE GRENADINES': 'VC',
  VENEZUELA: 'VE',
  'VIRGIN ISLANDS, BRITISH': 'VG',
  'VIRGIN ISLANDS, U.S.': 'VI',
  'VIET NAM': 'VN',
  VANUATU: 'VU',
  'WALLIS AND FUTUNA': 'WF',
  SAMOA: 'WS',
  YEMEN: 'YE',
  MAYOTTE: 'YT',
  'SOUTH AFRICA': 'ZA',
  ZAMBIA: 'ZM',
  ZIMBABWE: 'ZW',
};
