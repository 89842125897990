import { createStyles } from '@mantine/core';

const useStyles = createStyles({
  teamColorWrapper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    scale: `1.4`,
  },
});

function GamecenterPlayerCardTeamColor({ fill }: { fill: string }) {
  const { classes } = useStyles();

  return (
    <div className={classes.teamColorWrapper}>
      <svg
        width="99"
        height="132"
        viewBox="0 0 99 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1187_7713)">
          <path d="M63.5208 0L0 123.844H44.3567L107.878 0H63.5208Z" fill={fill} />
          <path d="M121.619 97.1955L86.3281 166H41.9714L121.619 10.7152V97.1955Z" fill={fill} />
          <path d="M39.4354 0L25.255 28.2412H40.0406L54.221 0H39.4354Z" fill={fill} />
          <path
            d="M63.5208 0L0 123.844H44.3567L107.878 0H63.5208Z"
            fill="black"
            fillOpacity="0.2"
          />
          <path
            d="M121.619 97.1955L86.3281 166H41.9714L121.619 10.7152V97.1955Z"
            fill="black"
            fillOpacity="0.2"
          />
          <path
            d="M39.4354 0L25.255 28.2412H40.0406L54.221 0H39.4354Z"
            fill="black"
            fillOpacity="0.2"
          />
        </g>
        <defs>
          <clipPath id="clip0_1187_7713">
            <rect width="99" height="132" fill="white" transform="matrix(1 0 0 -1 0 132)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default GamecenterPlayerCardTeamColor;
